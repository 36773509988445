<template>
  <el-card  class="box-card shadow" >
    <div style="display: flex; justify-content: center;">
      <el-button  v-on:click="reloadPage" icon="el-icon-refresh"></el-button>
      <h3 style="padding: 0 20px;" > Cotización Número: <span style="color: yellow;">{{this.$route.params.id}}</span></h3>
            <div class="col-md-7">
              <el-input
                type="text"
                placeholder="Titulo de la cotizacion"
                v-model="Titulo"
                :disabled="!isEditable"
                style="width: 100%"
              />
            </div>
            <div class="col-md-2">
              <el-button
                :type="typeButton"
                icon="el-icon-edit"
                @click="toggleEditable"
                style="width: 100%; padding: 9px 12px"
              >
                {{ buttonText }}
              </el-button>
            </div>
          </div>
  </el-card>
<div v-if="isLoggedIn" class="container-fluid">
  <br>
  <el-row :gutter="20">
    <el-col :span="6">
      <el-card  class="box-card shadow" >
          <h3>Cotizaciones Abiertas</h3>
          <el-button type="success" @click="downloadExcelFull(OpenCot)" icon="el-icon-download">Descargar Excel</el-button>
          <div v-for='(imp, key) in OpenCot' :key="key" >
          <el-button class="left-align" @click="RouteToCot(imp.ID)" >
              <span class="cot-id">{{imp.ID}}</span> - <span class="cot-date">{{imp.DATE}}</span> {{ imp.TITULO }} </el-button>
        </div>
      </el-card>
    </el-col>
      <el-col :span="17">
        <el-card  class="box-card shadow" >
        <h3>Solicitudes Detalladas</h3>
        <i class="bi bi-arrow-down-square-fill"></i>
          <el-button type="success" @click="downloadExcelFull(CotByUsr)" icon="el-icon-download">Descargar Excel</el-button>
        <table>
      <thead>
        <tr class="green">
          <th>Tipo</th>
          <th>Artcod</th>
          <th>Articulo</th>
          <th>Cantidad</th>
          <th>Empresa</th>
          <th>Proveedor(sol)</th>
          <th>Observ(Solicitante)</th>
          <th>Nota Compras</th>
          <th>Url</th>
          <th>Urgencia</th>
          <th>Acciones</th>
        </tr>
      </thead>
      <tbody>
            <tr v-for='(imp, key) in CotByUsr' :key="key">
              <td v-html="imp.tipo"></td>
              <td v-html="imp.articulo"></td>
              <td v-html="imp.descrp"></td>
              <td v-html="imp.cantidad"></td>
              <td v-html="imp.empresa"></td>
              <td v-html="imp.provee"></td>
              <td v-html="imp.observacion.slice(0, 50)"></td>
              <td v-html="imp.obscot.slice(0, 50)"></td>
              <td ><a :href="imp.url" target="_blank" >{{imp.url.slice(0, 40)}}</a></td>
              <td>
                <span :class="{
                  'red-text': imp.urg === 'Urg',
                  'yellow-text': imp.urg === '72Hs',
                  'green-text': imp.urg === '10D',
                  'cyan-text': imp.urg === '30D'
                }">{{imp.urg}}</span>
              </td>
              <td>
                <el-button type="primary" size="mini" icon="el-icon-edit" @click="VerObservacionCompras(imp.itm,imp.obscot)" circle></el-button>
                <el-button type="primary" size="mini" icon="el-icon-picture" @click="VerImg(imp.adj)" circle v-if="imp.adj.length!==0"></el-button>
                <el-button type="danger" size="mini" icon="el-icon-close" @click="BorrarItm(imp.itm,imp.obscot)" circle></el-button>
              </td>
            </tr>
          </tbody>
    </table>
  </el-card>
  <br>
  <el-card  class="box-card shadow" >
    <h3>Solicitudes Agrupadas</h3>
    <el-button type="success" @click="downloadExcel(CotSum)" icon="el-icon-download" >Descargar Excel</el-button>
        <table>
      <thead>
        <tr class="green">
          <th>Articulo</th>
          <th>Cantidad</th>
          <th>Observacion</th>
        </tr>
      </thead>
      <tbody>
            <tr v-for='(imp, key) in CotSum' :key="key">
              <td v-html="imp.articulo"></td>
              <td v-html="imp.cantidad"></td>
              <td v-html="imp.observacion" ></td>
            </tr>
          </tbody>
    </table>
  </el-card>

  <br>
<el-card class="box-card shadow" style="text-align: center">
  <el-popconfirm
    confirm-button-text="Si"
    cancel-button-text="No"
    icon-color="#626AEF"
    title="Esto borrará la cotizacion y volverá a dejar disponible los items para re agrupar en otra cotizacion, desea continuar?"
    @confirm="cerrarCotizacion('Anulada')"
  >
    <template #reference>
      <el-button type="warning">Deshacer</el-button>
    </template>
  </el-popconfirm>

  <el-popconfirm
    confirm-button-text="Si"
    cancel-button-text="No"
    icon-color="#626AEF"
    title="Esto Cerrará la cotizacion y ya no se verán los items de la cotizacion, desea continuar?"
    @confirm="cerrarCotizacion('Finalizado')"
  >
    <template #reference>
      <el-button type="success" >Aprobar y Finalizar</el-button>
    </template>
  </el-popconfirm>

  <el-popconfirm
    confirm-button-text="Si"
    cancel-button-text="No"
    icon-color="#626AEF"
    title="Esto Cancela la cotizacion y ya no se verán los items de la cotizacion, desea continuar?"
    @confirm="cerrarCotizacion('Rechazado')"
  >
    <template #reference>
      <el-button type="danger" >Cancelar SC</el-button>
    </template>
  </el-popconfirm>

  <el-button text @click="dialogVisibleGlobal = true" type="primary">
    Observacion global
  </el-button>
</el-card>
</el-col>
    </el-row>
  </div>
  <br><br>
  <el-dialog
    v-model="dialogVisibleGlobal"
    title="Observacion Global"
    width="30%"
  >
    <div class="row">
        <div class="col-md-12">
          <el-input v-model="ObservCOT_gral" placeholder='Ingresar un comentario, nota, observacion, etc.' :rows="15" type="textarea" />
        </div>
      </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisibleGlobal = false">Cancelar</el-button>
        <el-button type="primary" @click="UpdateObsCotGlobal(this)">
          Guardar
        </el-button>
      </span>
    </template>
  </el-dialog>

  <el-dialog
    v-model="dialogVisible"
    title="Observacion"
    width="30%"
  >
    <div class="row">
        <div class="col-md-12">
          <el-input v-model="ObserDialog" placeholder='Ingresar un comentario, nota, observacion, etc.' :rows="15" type="textarea" />
        </div>
      </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false">Cancelar</el-button>
        <el-button type="primary" @click="UpdateObsCot(this)">
          Guardar
        </el-button>
      </span>
    </template>
  </el-dialog>
  <el-dialog
    v-model="CierreVisible"
    title="Cierre / Finalización Cotización"
    width="30%"
  >
    <div class="row">
        <div class="col-md-12">
          <el-input v-model="CierreDialog" placeholder='ingrese el motivo del ciere de la cotizacion actual' :rows="15" type="textarea" />
        </div>
      </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="CierreVisible = false">Cancelar</el-button>
        <el-button type="primary" @click="CierreCot(this)">
          Guardar
        </el-button>
      </span>
    </template>
  </el-dialog>

  <el-dialog
    v-model="this.imgVisible"
    title="IMAGEN"
    width="30%"
  >
    <div class="row">
        <vue-pdf-embed :source="imgData" name="plugin2" v-if="imgData.includes('pdf;')"/>
      <embed width="100%" height="100%" name="plugin2" :src="imgData" type="image/png" v-if="!imgData.includes('pdf;')">
      </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="this.imgVisible = false">Cerrar</el-button>
      </span>
    </template>
  </el-dialog>

</template>
<script>
import { mapGetters } from 'vuex'
// import config from '../../public/config.json'
import axios from 'axios'
import router from '../router'
import shared from '../shared/shared'
import * as XLSX from 'xlsx'
import { ref } from 'vue'
import VuePdfEmbed from 'vue-pdf-embed'

export default {
  extends: shared,
  components: {
    VuePdfEmbed
  },
  updated () {
    document.title = 'Cot #' + this.$route.params.id
  },
  mounted: function () {
    document.title = 'Cot #' + this.$route.params.id
    var self = this
    self.PrepSolicitud.length = 0
    self.Solicitud.length = 0
    self.Precot.length = 0
    self.GetOpenCot()
    // self.getCotSum()
    self.GetCotByUsr()
    self.getFstCot()
  },
  beforeMount: function () {
    var self = this
    self.getPendienteXusr()
  },
  watch: {
  },
  data: function () {
    return {
      typeButton: 'success',
      Titulo: '',
      isEditable: false,
      buttonText: 'editar',
      FstCot: [{ ID: '' }],
      COT_Fst_ID: '',
      ObserDialog: '',
      CierreDialog: '',
      CierreVisible: ref(false),
      imgVisible: ref(false),
      imgData: '',
      Cot_itm: '',
      dialogVisible: ref(false),
      INS_SC_ID: '',
      INS_SC_ITM: '',
      NroCotizacion: 1,
      tipproductos: [],
      Articulos: [],
      empresas: [],
      proveedor: [],
      s_item: 1,
      s_tippro: '',
      s_artcod: '',
      s_cantidad: '',
      s_empresa: '',
      s_proveedor: '',
      s_observacion: '',
      s_Url: '',
      s_familia: '',
      s_prodNoExiste: false,
      PrepSolicitud: [{
        NroSol: '',
        item: '',
        tippro: '',
        artcod: '',
        descrpProd: '',
        cantidad: '',
        empresa: '',
        proveedor: '',
        observacion: '',
        url: '',
        prodNoExiste: false
      }],
      PendienteXusr: [{
        ARTCOD: '1',
        CANTIDAD: 0,
        EMPRESA: 'comcam',
        ID_ITM: 0,
        ID_SO: 0,
        OBSERV: '',
        TIPPRO: '',
        URL: '',
        descrp: null,
        provee: '',
        Adj: '',
        usrid: ''
      }],
      OpenCot: [{
        ID: '',
        DATE: '',
        OBSERV: '',
        TITULO: ''
      }],
      dialogVisibleGlobal: ref(false),
      ObservCOT_gral: '',
      CotSum: [{
        articulo: '',
        cantidad: '',
        observacion: ''
      }],
      CotByUsr: [{
        tipo: '',
        articulo: '',
        descrp: '',
        cantidad: '',
        empresa: '',
        observacion: '',
        url: '',
        user: '',
        itm: '',
        obscot: '',
        adj: '',
        provee: '',
        urg: ''
      }],
      Precot: [{
        ARTCOD: '',
        CANTIDAD: '',
        EMPRESA: '',
        ID_ITM: '',
        ID_SO: '',
        OBSERV: '',
        TIPPRO: '',
        URL: '',
        descrp: ''
      }],
      Solicitud: [{
        NroSol: '',
        item: '',
        tippro: '',
        artcod: '',
        cantidad: '',
        empresa: '',
        proveedor: '',
        observacion: '',
        prodNoExiste: false,
        cot: false
      }],
      Cotizacion: [{
        NroSol: '',
        item: '',
        NroCot: '',
        Estado: 'Agrupado'
      }],
      artnom: '',
      EstadoR: [],
      COT_ID: '',
      Ins_artcod: '1',
      Ins_Tippro: 'rpts',
      Ins_Empresa: 'comcam',
      Ins_Cantid: '',
      Ins_obs: '',
      Ins_url: ''
      // ip: '34.67.86.78:8080'
    }
  },
  computed: {
    ...mapGetters(['getToken']),
    ...mapGetters(['isLoggedIn']),
    ...mapGetters(['getArea'])
  },
  methods: {
    BorrarItm (itm, obscot) {
      console.log('BorraITM : ' + itm + ' ' + obscot)
      if (obscot !== null) {
        console.log(obscot.length)
        if (obscot.length > 5) {
          this.Cot_itm = itm
          this.bajaCotItm()
        }
      } else { this.errorMsg('Se debe grabar el motivo por el cual se quita el registro de la cotizacion') }
    },
    reloadPage () {
      location.reload()
    },
    toggleEditable () {
      this.isEditable = !this.isEditable
      if (this.isEditable) {
        this.buttonText = 'guardar'
        this.typeButton = 'warning'
      } else {
        this.buttonText = 'editar'
        // this.persistirTitulo()
        this.UpdateTitulo()
        this.typeButton = ''
        console.log('Titulo guardado: ', this.Titulo)
      }
    },
    descrpARTcod () {
      this.artnom = this.Articulos.find(art => art.artcod === this.s_artcod).descrp
    },
    RemoveCot (SC, Itm, index) {
      var self = this
      this.PendienteXusr.push(self.Precot.find(art => art.ID_SO === SC & art.ID_ITM === Itm))
      this.Precot.splice(index, 1)
    },
    VerObservacionCompras (itm, obs) {
      this.Cot_itm = itm
      this.ObserDialog = obs
      this.dialogVisible = true
    },
    GrabarObservacionCompras () {
      this.dialogVisible = ref(false)
      this.dialogVisible = false
      this.UpdateObsCot()
    },
    VerImg (img) {
      this.imgData = img
      this.imgVisible = ref(true)
    },
    CierreCot () {
      this.CierreVisible = ref(false)
    },
    reset () {
      /* this.CotByUsr.length = 0
      this.CotSum.length = 0
      Notification('reset') */

      var self = this
      /* self.PrepSolicitud.length = 0
      self.Solicitud.length = 0
      self.Precot.length = 0 */
      self.GetOpenCot()
    },
    RouteToCot (e) {
      this.reset()
      router.push('/cotinfo/' + e)
    },
    downloadExcelFull (CotByUsr) {
      const CotByUsrWithoutAdj = CotByUsr.map(item => {
        const { adj, ...rest } = item
        return rest
      })
      this.downloadExcel(CotByUsrWithoutAdj)
    },
    downloadExcel (CotByUsr) {
      const ws = XLSX.utils.json_to_sheet(CotByUsr)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
      XLSX.writeFile(wb, 'table.xlsx')
    },
    vaciarSolicitud () {
      this.Solicitud.length = 0
    },
    cerrarCotizacion (estado) {
      // verificar si el campo observacion está con algun dato, sino pedir completarlo
      console.log('------------------------cerrarCotizacion-------------------')
      console.log(this.ObservCOT_gral)
      if (this.ObservCOT_gral !== 'undefined' && this.ObservCOT_gral !== null && this.ObservCOT_gral !== '') {
        this.updCotCls(estado)
        this.RouteToCot(this.COT_Fst_ID)
      } else {
        this.errorMsg('Se debe registrar el motivo del cierre en la Observacion Global de la Cotizacion')
      }
    },
    openFSL () {
      this.loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
    },
    closeFSL () {
      this.loading.close()
    },
    getCotSum: function (e) {
      console.log('getCotSum')
      var self = this
      self.openFSL()
      this.s_artcod = []
      const url = 'http://' + this.getArea.ip + '/sc/getCotSum/params;id=' + this.$route.params.id

      axios.interceptors.response.use(function (response) {
        return response
      }, function (error) {
        if (error.response.status === 401) {
          router.push('/login')
        }
        return Promise.reject(error)
      })
      axios.get(url, {
        dataType: 'json',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: self.getToken.token
        },
        mode: 'no-cors',
        credentials: 'include'
      })
        .then(function (response) {
          localStorage.setItem('Cotsum', JSON.stringify(response.data).split('T00:00:00Z[-03:00]').join(''))
          self.CotSum = JSON.parse(localStorage.getItem('Cotsum'))
          self.closeFSL()
          self.GetCotByUsr()
          console.log('fin getCotSum')
        })
        .catch(function (error) {
          console.log(error)
          self.closeFSL()
        })
    },
    UpdateObsCot (self2) {
      var self = this
      self2.dialogVisible = false
      console.log('http://' + this.getArea.ip + '/sc/updObsCot/params;cot_id=' + this.$route.params.id + ';cot_itm=' + this.Cot_itm + ';observ=' + this.ObserDialog)
      self.openFSL()
      fetch('http://' + this.getArea.ip + '/sc/updObsCot/params;cot_id=' + this.$route.params.id + ';cot_itm=' + this.Cot_itm + ';observ=' + encodeURIComponent(this.ObserDialog), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: self.getToken.token
        },
        body: JSON.stringify({
          // imagen: this.image
        })
      })
        .then(async response => {
          this.EstadoR = await response.json()
          if (this.EstadoR[0].user === self.getToken.user) {
          }
          self.GetCotByUsr()
          self.closeFSL()
          console.log('fin UpdateObsCot')
        })
        .catch(error => {
          console.error('Mensaje de error: ', error)
          this.errorMsg(error)
          self.closeFSL()
        })
    },
    getFstCot: function (e) {
      console.log('proximo COT ID (getFstCot)')
      var self = this
      const url = 'http://' + this.getArea.ip + '/sc/getFstCot/'
      axios.interceptors.response.use(function (response) {
        return response
      }, function (error) {
        if (error.response.status === 401) {
          router.push('/login')
        }
        return Promise.reject(error)
      })
      axios.get(url, {
        dataType: 'json',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: self.getToken.token
        },
        mode: 'no-cors',
        credentials: 'include'
      })
        .then(function (response) {
          localStorage.setItem('FstCot', JSON.stringify(response.data).split('T00:00:00Z[-03:00]').join(''))
          self.FstCot = JSON.parse(localStorage.getItem('FstCot'))
          console.log('cotizacion con el menor indice')
          console.log(self.FstCot[0].ID)
          self.COT_Fst_ID = self.FstCot[0].ID
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    updCotCls (estado) {
      console.log('updCotCls')
      var self = this
      self.openFSL()
      fetch('http://' + this.getArea.ip + '/sc/updCotCls/params;cot_id=' + this.$route.params.id + ';observ=' + estado, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: self.getToken.token
        },
        body: JSON.stringify({
          // imagen: this.image
        })
      })
        .then(async response => {
          this.EstadoR = await response.json()
          if (this.EstadoR[0].user === self.getToken.user) {
          }
          self.GetCotByUsr()
          self.closeFSL()
          console.log('fin updCotCls')
        })
        .catch(error => {
          console.error('Mensaje de error: ', error)
          this.errorMsg(error)
          self.closeFSL()
        })
    },
    UpdateObsCotGlobal (self2) {
      var self = this
      self2.dialogVisibleGlobal = false
      console.log('http://' + this.getArea.ip + '/sc/updObsCotGlobal/params;cot_id=' + this.$route.params.id + ';observ=' + this.ObserDialog)
      self.openFSL()
      fetch('http://' + this.getArea.ip + '/sc/updObsCotGlobal/params;cot_id=' + this.$route.params.id + ';observ=' + encodeURIComponent(this.ObservCOT_gral), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: self.getToken.token
        },
        body: JSON.stringify({
          // imagen: this.image
        })
      })
        .then(async response => {
          this.EstadoR = await response.json()
          if (this.EstadoR[0].user === self.getToken.user) {
          }
          self.GetCotByUsr()
          self.closeFSL()
        })
        .catch(error => {
          console.error('Mensaje de error: ', error)
          this.errorMsg(error)
          self.closeFSL()
        })
    },
    UpdateTitulo () {
      console.log('UpdateTitulo')
      var self = this
      console.log('http://' + this.getArea.ip + '/sc/UpdateTitulo/params;cot_id=' + this.$route.params.id + ';observ=' + self.Titulo)
      self.openFSL()
      fetch('http://' + this.getArea.ip + '/sc/UpdateTitulo/params;cot_id=' + this.$route.params.id + ';observ=' + encodeURIComponent(self.Titulo), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: self.getToken.token
        },
        body: JSON.stringify({
          // imagen: this.image
        })
      })
        .then(async response => {
          this.EstadoR = await response.json()
          self.GetCotByUsr()
          self.closeFSL()
          console.log('fin UpdateTitulo')
          this.GetOpenCot()
        })
        .catch(error => {
          console.error('Mensaje de error: ', error)
          this.errorMsg(error)
          self.closeFSL()
        })
    },
    bajaCotItm () {
      var self = this
      console.log('http://' + this.getArea.ip + '/sc/updObsCot/params;cot_id=' + this.$route.params.id + ';cot_itm=' + this.Cot_itm)
      self.openFSL()
      fetch('http://' + this.getArea.ip + '/sc/bajaCotItm/params;cot_id=' + this.$route.params.id + ';cot_itm=' + this.Cot_itm, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: self.getToken.token
        },
        body: JSON.stringify({
          // imagen: this.image
        })
      })
        .then(async response => {
          this.EstadoR = await response.json()
          if (this.EstadoR[0].user === self.getToken.user) {
          }
          self.GetCotByUsr()
          self.closeFSL()
        })
        .catch(error => {
          console.error('Mensaje de error: ', error)
          this.errorMsg(error)
          self.closeFSL()
        })
    },
    getPendienteXusr: function (e) {
      var self = this
      self.openFSL()
      this.s_artcod = []
      const url = 'http://' + this.getArea.ip + '/sc/getPendiente/params;usrid=' + this.getToken.user + ';byusr=N'

      axios.interceptors.response.use(function (response) {
        return response
      }, function (error) {
        if (error.response.status === 401) {
          router.push('/login')
        }
        return Promise.reject(error)
      })
      axios.get(url, {
        dataType: 'json',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: self.getToken.token
        },
        mode: 'no-cors',
        credentials: 'include'
      })
        .then(function (response) {
          localStorage.setItem('PendienteXusr', JSON.stringify(response.data).split('T00:00:00Z[-03:00]').join(''))
          self.PendienteXusr = JSON.parse(localStorage.getItem('PendienteXusr'))
          self.closeFSL()
        })
        .catch(function (error) {
          console.log(error)
          self.closeFSL()
        })
    },
    GetOpenCot: function (e) {
      console.log('getOpenCot')
      var self = this
      const url = 'http://' + this.getArea.ip + '/sc/getOpenCot/'
      console.log(url)
      axios.interceptors.response.use(function (response) {
        return response
      }, function (error) {
        if (error.response.status === 401) {
          router.push('/login')
        }
        return Promise.reject(error)
      })
      axios.get(url, {
        dataType: 'json',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: self.getToken.token
        },
        mode: 'no-cors',
        credentials: 'include'
      })
        .then(function (response) {
          localStorage.setItem('OpenCot', JSON.stringify(response.data).split('T00:00:00Z[-03:00]').join(''))
          self.OpenCot = JSON.parse(localStorage.getItem('OpenCot'))
          const cotid = self.$route.params.id
          console.log(cotid)
          self.ObservCOT_gral = ''
          self.ObservCOT_gral = self.OpenCot.find(cot => cot.ID === parseInt(cotid)).OBSERV
          // console.log(self.ObservCOT_gral)
          self.getCotSum()
          self.Titulo = ''
          self.Titulo = self.OpenCot.find(cot => cot.ID === parseInt(cotid)).TITULO
          console.log('titulo en GetOpenCot: ' + self.Titulo)
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    GetCotByUsr: function (e) {
      console.log('GetCotByUsr')
      var self = this
      const url = 'http://' + this.getArea.ip + '/sc/getCotById/params;id=' + this.$route.params.id
      axios.interceptors.response.use(function (response) {
        return response
      }, function (error) {
        if (error.response.status === 401) {
          router.push('/login')
        }
        return Promise.reject(error)
      })
      axios.get(url, {
        dataType: 'json',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: self.getToken.token
        },
        mode: 'no-cors',
        credentials: 'include'
      })
        .then(function (response) {
          localStorage.setItem('CotByUsr', JSON.stringify(response.data).split('T00:00:00Z[-03:00]').join(''))
          self.CotByUsr = JSON.parse(localStorage.getItem('CotByUsr'))
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    consolidar () {
      // hacer un for que recorra la presolicitud y meta todo a Inser_COT
      this.Precot.forEach(solid => this.functionTest(solid))
      this.successMsg('se registró la Cotizacion NRO:' + this.COT_ID)
      // borrar el listado de presolicitud
      this.vaciarPreCotizacion()
      this.getPendienteXusr()
    },
    functionTest (e) {
      this.INS_SC_ID = e.ID_SO
      this.INS_SC_ITM = e.ID_ITM
      this.Inser_COT()
    },
    Inser_COT () {
      var self = this
      self.openFSL()
      // http://34.69.86.51:8080/sc/INS_COT/params;cot_id=29;sc_id=8;sc_itm=7;
      fetch('http://' + this.getArea.ip + '/sc/INS_COT/params;cot_id=' + self.COT_ID + ';sc_id=' + self.INS_SC_ID + ';sc_itm=' + self.INS_SC_ITM, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: self.getToken.token
        },
        body: JSON.stringify({
        })
      })
        .then(async response => {
          this.EstadoR = await response.json()
          self.closeFSL()
        })
        .catch(error => {
          console.error('Mensaje de error: ', error)
          this.errorMsg(error)
          self.closeFSL()
        })
    }

  }
}
</script>
<style>
.el-input--suffix .el-input__inner {
    margin-bottom: 15px;
}
.el-button--primary.is-plain {
  margin-bottom: 15px;
  border-radius: 20px;
  background: linear-gradient(to bottom, #007aff, #0060ff);
  color: white;
  padding: 10px 20px;
  font-size: 14px;
  border: none;
}
a {
    color: white;
    text-decoration: underline;
}
.cot-id {
color: yellow;
}
.cot-date {
color: #f56c6c;
}
.left-align {
  text-align: left;
}
</style>
