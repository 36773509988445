<template>
    <h1>Solicitud de Compra</h1>
  <div v-if="isLoggedIn" class="container-fluid">
  <el-card  class="box-card shadow">
  <h5 style="color:red">Version Beta
    <el-popover
    placement="right-end"
    title="Últimos cambios"
    :width="590"
    trigger="hover"
>
    <template #reference>
      <el-button class="m-3"> (19/07/2023)</el-button>
    </template>
    <template #default>
      <pre>
        * se corrigio la carga de articulos sin tipo
        * se agregó la opcion de limpiar las seleccion de los desplegables
      </pre>
    </template>
</el-popover>

  </h5>
  <h5 style="color:yellow">IMPORTANTE: si se detectan errores o inconsistencias porfavor notifique aqui ↓</h5>
    <el-button type="warning" onclick="location.href='https://grupopenna.com.ar/issues/'">Notificar incidente <i class="el-icon-warning"></i></el-button>
  </el-card>
  <div class="col-md-2">
      <el-button type="warning" v-on:click="reloadPage" icon="el-icon-refresh"></el-button>
      <br>
  </div>
  <!--------------------------------------------------------------------------ARMADO DE SOLICITUD------------------------------------------------------------------------------>
  <el-card  class="box-card shadow">
  <h3>Carga de datos</h3>
  <br>
  <div class="row">
    <div class="col-md-2">
      <el-select v-model="s_empresa" @change="ToSelectEmpresa()" placeholder="Empresa" clearable >
                    <el-option
                      v-for="item in empresas"
                      :key="item.Base"
                      :label="item.Empresa"
                      :value="item.Base">
                    </el-option>
                  </el-select>
    </div>
    <div class="col-md-2">
      <el-select v-model="s_tippro" @change="getArtcod()" filterable placeholder="Elije el tipo de Producto" clearable >
                    <el-option
                      v-for="item in tipproductos"
                      :key="item.TIPPRO"
                      :label="item.DESCRP"
                      :value="item.TIPPRO">
                    </el-option>
                  </el-select>
    </div>
    <div class="col-md-2">
      <el-select v-model="s_artcod" @change="descrpARTcod()" filterable placeholder="Articulo" clearable >
                    <el-option
                      v-for="item in Articulos"
                      :key="item.artcod"
                      :label="item.descrp"
                      :value="item.artcod">
                    </el-option>
                  </el-select>
    </div>
    <div class="col-md-1">
      <el-input type='number' v-model="s_cantidad" placeholder= 'Cant.' @change="validarCarga()"/>
    </div>
    <div class="col-md-2">
      <el-select v-model="proveedor" filterable multiple placeholder="Proveedor Historico" clearable >
                  <el-option
                      v-for="item in proveedorHistorico"
                      :key="item.nrocta"
                      :label="item.razsoc"
                      :value="item.nrocta">
                    </el-option>
                  </el-select>
    </div>
    <div class="col-md-2">
      <el-select v-model="s_proveedor" filterable multiple placeholder="Proveedor x Empresa" clearable >
                    <el-option
                      v-for="item in proveedorxEmpresa"
                      :key="item.nrocta"
                      :label="item.razsoc"
                      :value="item.nrocta">
                    </el-option>
                  </el-select>
    </div>
    <div class="col-md-4">
      <el-input type='text' v-model="s_inexist" placeholder= 'Art. Inexistente' @change="articuloOR()" />
    </div>
    <br>
    <div class="col-md-2">
      <el-select v-model="RangoU" filterable placeholder="rango de urgencia" clearable >
                    <el-option
                      v-for="item in RangoUrgencia"
                      :key="item.Rango"
                      :label="item.Descrp"
                      :value="item.Rango">
                    </el-option>
                  </el-select>
    </div>
    <div class="col-md-5">
      <el-input type='text' v-model="s_Url" placeholder= 'URL' />
    </div>
    <br>
    <div class="col-md-5">
      <el-input type='textarea' v-model="s_observacion" placeholder= 'Observacion' rows="3"/>
    </div>
    <br>
    <br>
    <div class="col-md-2">
          <input @change='handleImage' type = 'file' id = 'uploadFile' accept='application/pdf, image/*' style="display:none">
    </div>
    <!----- <button v-if="0===1" @click="enviarTSTaADJ">Enviar a TST a ADJ desde SC</button>
    <h5>TSTs en componente SC: {{ TSTs }}</h5>
    <div class="col-md-6">
      <ADJ
        @enviar-tst="recibirTSTdeADJ"
        ref="tstComponent"
      />
    </div>------>
</div>
<!-----
<div class="col-md-2">
<vue-pdf-embed :source="this.image" name="plugin2" v-if="this.image.includes('pdf;')"/>
          <embed width="100%" height="100%" name="plugin2" :src="this.image" type="image/png" v-if="!this.image.includes('pdf;')">
          </div>
------>
<div class="col-md-2">
  <vue-pdf-embed :source="this.image" name="plugin2" v-if="this.image.includes('pdf;')"/>
  <img :src="this.image" v-else-if="this.image.includes('image;')"/>
</div>

  <br>
  <div class="col-md-2">
      <el-button type="primary"  @click="addPreSolicitud" plain>Agregar Producto a Armdao de solicitud <i class="el-icon-circle-plus"></i></el-button>
  </div>
</el-card>
<br>
<!--------------------------------------------------------------------------ARMADO DE SOLICITUD------------------------------------------------------------------------------>
<el-card class="box-card shadow">
<div class='row'>
    <h5>Armado de solicitud</h5>
    <br>
    <table>
      <thead>
        <tr class="green">
          <th>Nro</th>
          <th>Urgencia</th>
          <th>Tipo</th>
          <th>Articulo</th>
          <th>Cantidad</th>
          <th>Empresa</th>
          <th>proveedor</th>
          <th>Observacion</th>
          <th>img</th>
          <!--- <th>ADJ</th> -->
          <th>URL</th>
          <th>Borrar</th>
        </tr>
      </thead>
      <tbody>
            <tr v-for='(imp, i) in PrepSolicitud' :key='i'>
              <td v-html="imp.item"></td>
              <td>
                <span :class="{
                  'red-text': imp.urg === 'Urg',
                  'yellow-text': imp.urg === '72Hs',
                  'green-text': imp.urg === '10D',
                  'cyan-text': imp.urg === '30D'
                }">{{imp.urg}}</span>
              </td>
              <td v-html="imp.tippro"></td>
              <td v-html="imp.descrpProd" v-if="imp.inexist.length===0"></td>
              <td v-html="imp.inexist" v-if="imp.inexist.length!==0"></td>
              <td v-html="imp.cantidad"></td>
              <td v-html="imp.empresa"></td>
              <td v-html="imp.proveedor"></td>
              <td v-html="imp.observacion.slice(0, 50)"></td>
              <td >
                <vue-pdf-embed :source="imp.img" name="plugin2" v-if="imp.img.includes('pdf;') & imp.img.length>0" @click="VerImg(imp.img)"/>
                <embed width="30px" height="30px" name="plugin2" :src="imp.img" type="image/png" v-if="!imp.img.includes('pdf;') & imp.img.length>0" @click="VerImg(imp.img)">
              </td>
              <!--- <td ><el-button type="primary" size="mini" icon="el-icon-picture" @click="enviarTSTaADJGaleria(imp.tstadj)" circle v-if="imp.tstadj.length!==0"></el-button></td> -->
              <td ><a :href="imp.url" target="_blank" >{{imp.url.substring(0, 40)}}</a></td>
              <td ><el-button type="danger"  @click="this.PrepSolicitud.splice(i, 1)" plain><i class="el-icon-delete"></i></el-button></td>
            </tr>
          </tbody>
    </table>
  </div>
  <div class="col-md-2">
      <el-button type="success"  @click="getProxScID()" plain>Guardar Solicitud</el-button>
  </div>
  <div class="col-md-2">
      <el-button type="warning"  @click="vaciarPreSolicitud" plain>Vaciar listado pre orden</el-button>
  </div>
</el-card>
    <br>
<el-card class="box-card shadow">
  <h3>Solicitudes del usuario {{ this.getToken.user }} que aún no fueron tomados por el área de compras</h3>
  <el-button type="primary" plain><router-link to="/screport" >Reporte Socicutdes <i class="el-icon-search"></i></router-link></el-button>
  <div class='row'>
    <br>
    <table>
      <thead>
        <tr class="green">
          <th>Nro Sol</th>
          <th>Item</th>
          <th>Urgencia</th>
          <th>Tipo</th>
          <th>Articulo</th>
          <th>Cantidad</th>
          <th>Empresa</th>
          <th>proveedor</th>
          <th>Observacion</th>
          <th>URL</th>
          <th>img</th>
          <th>Borrar</th>
          <!--- <th>Adjunto</th> -->
        </tr>
      </thead>
      <tbody>
            <tr v-for='(imp, i) in PendienteXusr' :key='i'>
              <td v-html="imp.ID_SO"></td>
              <td v-html="imp.ID_ITM"></td>
              <td>
                <span :class="{
                  'red-text': imp.urg === 'Urg',
                  'yellow-text': imp.urg === '72Hs',
                  'green-text': imp.urg === '10D',
                  'cyan-text': imp.urg === '30D'
                }">{{imp.urg}}</span>
              </td>
              <td v-html="imp.TIPPRO"></td>
              <td v-html="imp.descrp"></td>
              <td v-html="imp.CANTIDAD"></td>
              <td v-html="imp.EMPRESA"></td>
              <td v-html="imp.provee.replace('PROVEEDOR GENERICO NO USAR |','')"></td>
              <td v-html="imp.OBSERV.slice(0, 50)"></td>
              <td ><a :href="imp.URL" target="_blank" >{{imp.URL.substring(0, 40)}}</a></td>
              <td ><el-button type="primary" size="mini" icon="el-icon-picture" @click="VerImg(imp.Adj)" circle v-if="imp.Adj.length!==0"></el-button></td>
              <!--- <td ><el-button type="primary" size="mini" icon="el-icon-picture" @click="enviarTSTaADJGaleria(imp.tstAdj)" circle v-if="imp.tstAdj.length>0"></el-button></td> -->
              <td>
                <el-popconfirm
                  confirm-button-text="Si"
                  cancel-button-text="No"
                  icon-color="#626AEF"
                  title="Esto borrará la solicitud y dejara de estar disponible para agrupar en una cotizacion, desea continuar?"
                  @confirm="borrarSolicitud(imp.ID_SO,imp.ID_ITM, i)"
                >
                  <template #reference>
                    <el-button type="danger" size="mini" icon="el-icon-delete"></el-button>
                  </template>
                </el-popconfirm>
              </td>
            </tr>
          </tbody>
    </table>
  </div>
</el-card>
<br>
</div>
<!--------------------------------------------------------------------------POP UP Imagen clasica original------------------------------------------------------------------------------>
<el-dialog
    v-model="this.imgVisible"
    title="IMAGEN"
    width="30%"
  >
    <div class="row">
        <vue-pdf-embed :source="imgData" name="plugin2" v-if="imgData.includes('pdf;')"/>
      <embed width="100%" height="100%" name="plugin2" :src="imgData" type="image/png" v-if="!imgData.includes('pdf;')">
      </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="this.imgVisible = false">Cerrar</el-button>
      </span>
    </template>
  </el-dialog>
<!--------------------------------------------------------------------------POP UP Imagen clasica original------------------------------------------------------------------------------>

<!--------------------------------------------------------------------------POP UP TST ADHUNTOS------------------------------------------------------------------------------>
  <el-dialog
    v-model="this.AdjVisible"
    title="ADHUNTOS"
    width="30%"
  >
    <div class="row">
        <vue-pdf-embed :source="AdjData" name="plugin2" v-if="AdjData.includes('pdf;')"/>
      <embed width="100%" height="100%" name="plugin2" :src="AdjData" type="image/png" v-if="!AdjData.includes('pdf;')">
      </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="this.AdjVisible = false">Cerrar</el-button>
      </span>
    </template>
  </el-dialog>
  <!--------------------------------------------------------------------------POP UP TST ADHUNTOS------------------------------------------------------------------------------>
</template>
<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
import router from '../router'
import shared from '../shared/shared'
import { ref } from 'vue'
import VuePdfEmbed from 'vue-pdf-embed'
// import ADJ from '@/components/SC_adj.vue'
// import VueAnalytics from 'vue-analytics'
export default {
  extends: shared,
  components: {
    VuePdfEmbed //, ADJ
  },
  async mounted () {
    document.title = 'Solicitud de Compras'
    var self = this

    try {
      // await self.checkOnline() // Esperar a que se resuelva checkOnline
      self.getEmpresa()
      self.PrepSolicitud.length = 0
      self.Solicitud.length = 0
      self.proveedor.length = 0

    /* if (this.isLoggedIn === false) {
      router.push('/login')
    } */
    // VueAnalytics.gtag('set', { user_id: this.getUser.user })
    } catch (error) {
      console.log(error)
    }
  },
  beforeMount: function () {
    var self = this
    self.getPendienteXusr()
    // console.log('self.getIp.ip:' + self.getIp.ip)
  },
  data: function () {
    return {
      TSTs: '',
      RangoU: '',
      RangoUrgencia: [
        {
          Rango: '72Hs',
          Descrp: '72 Horas'
        },
        {
          Rango: '10D',
          Descrp: '10 Días'
        },
        {
          Rango: '30D',
          Descrp: '30 Días'
        },
        {
          Rango: 'Urg',
          Descrp: 'Urgente'
        }
      ],
      ip: '',
      // online: false,
      continuar: ref(true),
      imgVisible: ref(false),
      AdjVisible: ref(false),
      imgData: '',
      AdjData: '',
      fileSelected: '',
      image: '',
      NroSolicitud: 1,
      tipproductos: [],
      Articulos: [],
      empresas: [],
      proveedorConcat: '',
      proveedor: [{
        razsoc: '',
        nrocta: ''
      }],
      proveedorHistorico: [],
      proveedorxEmpresa: [],
      s_inexist: '',
      s_item: 1,
      s_tippro: '',
      s_artcod: '',
      s_cantidad: '',
      s_empresa: '',
      s_proveedor: '',
      s_observacion: '',
      s_Url: '',
      s_urg: '',
      s_familia: '',
      s_prodNoExiste: false,
      s_img: '',
      s_tstadj: '',
      PrepSolicitud: [{
        NroSol: '',
        item: '',
        tippro: '',
        artcod: '',
        descrpProd: '',
        cantidad: '',
        empresa: '',
        proveedor: '',
        observacion: '',
        url: '',
        inexist: '',
        img: '',
        tstadj: '',
        urg: ''
      }],
      PendienteXusr: [{
        ARTCOD: '',
        CANTIDAD: 0,
        EMPRESA: '',
        ID_ITM: 0,
        ID_SO: 0,
        OBSERV: '',
        TIPPRO: '',
        URL: '',
        descrp: null,
        provee: '',
        Adj: '',
        tstAdj: '',
        urg: ''
      }],
      Solicitud: [{
        NroSol: '',
        item: '',
        tippro: '',
        artcod: '',
        cantidad: '',
        empresa: '',
        proveedor: '',
        observacion: '',
        inexist: '',
        cot: false
      }],
      Cotizacion: [{
        NroSol: '',
        item: '',
        NroCot: '',
        Estado: 'Agrupado'
      }],
      artnom: '',
      EstadoR: [],
      SC_ID: '',
      Ins_artcod: '1',
      Ins_Tippro: 'rpts',
      Ins_Empresa: 'comcam',
      Ins_Cantid: '',
      Ins_obs: '',
      Ins_url: '',
      Ins_Inexist: '',
      ins_provee: '',
      ins_img: '',
      ins_tstadj: '',
      ins_urg: ''
    }
  },
  computed: {
    ...mapGetters(['getToken']),
    ...mapGetters(['isLoggedIn']),
    ...mapGetters(['getArea']),
    ...mapGetters(['getUser']),
    ...mapGetters(['getIp'])
  },
  methods: {
    validarCarga () {
      if (this.s_cantidad < 1) {
        this.errorMsg('la cantidad debe ser mayor a cero')
        return false
      }
      if (this.s_inexist.length === 0 & this.s_artcod.length === 0) {
        this.errorMsg('Se debe definir el artículo')
        return false
      }
      return true
    },
    VerImg (img) {
      this.imgData = img
      this.imgVisible = true
    },
    VerADJ (adj) {
      this.AdjData = adj
      this.AdjVisible = true
    },
    reloadPage () {
      location.reload()
    },
    updateTSTs (newValue) {
      this.TSTs = newValue
    },
    descrpARTcod () {
      this.artnom = this.Articulos.find(art => art.artcod === this.s_artcod).descrp
      if (this.s_inexist.length > 0) {
        this.s_inexist = ''
      }
      this.getProv()
    },
    addPreSolicitud () {
      if (this.validarCarga()) {
        if (this.s_proveedor.length > 0 & this.proveedor.length > 0) {
          this.proveedorConcat = this.s_proveedor + ',' + this.proveedor
        } else {
          this.proveedorConcat = this.s_proveedor + this.proveedor
        }
        if (this.s_cantidad > 0) {
          console.log('this.TSTs' + this.TSTs)
          this.PrepSolicitud.push(
            {
              NroSol: this.NroSolicitud,
              item: this.s_item,
              tippro: this.s_tippro,
              artcod: this.s_artcod,
              descrpProd: this.artnom,
              cantidad: this.s_cantidad,
              empresa: this.s_empresa,
              proveedor: this.proveedorConcat,
              observacion: this.s_observacion,
              inexist: this.s_inexist,
              url: this.s_Url,
              img: this.image,
              tstadj: this.TSTs,
              urg: this.RangoU
            }
          )
          this.s_item = this.s_item + 1
        }
        this.image = ''
      }
    },
    articuloOR () {
      console.log('evalua si es un articulo inexistente, caso posibivo borra tipo y art')
      if (this.s_inexist.length > 0) {
        this.s_tippro = ''
        this.s_artcod = ''
        // this.tipproductos.length = 0
        this.Articulos.length = 0
      }
    },
    NuevaCotizacion () {
      console.log('Or')
      this.ticketF = 1
    },
    vaciarPreSolicitud () {
      this.PrepSolicitud.length = 0
    },
    vaciarSolicitud () {
      this.Solicitud.length = 0
    },
    openFSL () {
      this.loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
    },
    ToSelectEmpresa () {
      this.getProvXempr()
      this.getTippro()
    },
    closeFSL () {
      this.loading.close()
    },
    recibirTSTdeADJ (TST) {
      this.TSTs = TST
      console.log('Componente SC recibiento TST de Adj: ' + this.TSTs)
    },
    enviarTSTaADJ () {
      this.$refs.tstComponent.recibirTSTdelPadre(this.TSTs)
    },
    enviarTSTaADJGaleria (tst) {
      console.log('enviarTSTaADJGaleria: ' + tst)
      this.$refs.tstComponent.TstParaGaleria(tst)
    },
    getTippro: function (e) {
      var self = this
      self.openFSL()
      const url = 'http://' + this.getArea.ip + '/sc/getTippro/'

      axios.interceptors.response.use(function (response) {
        return response
      }, function (error) {
        if (error.response.status === 401) {
          router.push('/login')
          router.push(self.getToken.token)
        }
        return Promise.reject(error)
      })
      axios.get(url, {
        dataType: 'json',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: self.getToken.token
        },
        mode: 'no-cors',
        credentials: 'include'
      })
        .then(function (response) {
          localStorage.setItem('tippro', JSON.stringify(response.data).split('T00:00:00Z[-03:00]').join(''))
          self.tipproductos = JSON.parse(localStorage.getItem('tippro'))
          self.closeFSL()
        })
        .catch(function (error) {
          console.log('Catch getTippro')
          console.log(error)
          self.closeFSL()
        })
    },
    getArtcod: function (e) {
      var self = this
      self.openFSL()
      this.s_artcod = []
      const url = 'http://' + this.getArea.ip + '/sc/getArtcod/params;tippro=' + this.s_tippro

      axios.interceptors.response.use(function (response) {
        return response
      }, function (error) {
        if (error.response.status === 401) {
          router.push('/login')
          router.push(self.getToken.token)
        }
        return Promise.reject(error)
      })
      axios.get(url, {
        dataType: 'json',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: self.getToken.token
        },
        mode: 'no-cors',
        credentials: 'include'
      })
        .then(function (response) {
          localStorage.setItem('artcod', JSON.stringify(response.data).split('T00:00:00Z[-03:00]').join(''))
          self.Articulos = JSON.parse(localStorage.getItem('artcod'))
          console.log(url)
          self.closeFSL()
        })
        .catch(function (error) {
          console.log(error)
          self.closeFSL()
        })
    },
    getPendienteXusr: function (e) {
      var self = this
      self.openFSL()
      this.s_artcod = []
      const url = 'http://' + this.getArea.ip + '/sc/getPendiente/params;usrid=' + this.getToken.user + ';byusr=S'
      // console.log(url)
      axios.interceptors.response.use(function (response) {
        return response
      }, function (error) {
        if (error.response.status === 401) {
          router.push('/login')
          router.push(self.getToken.token)
        }
        return Promise.reject(error)
      })
      axios.get(url, {
        dataType: 'json',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: self.getToken.token
        },
        mode: 'no-cors',
        credentials: 'include'
      })
        .then(function (response) {
          localStorage.setItem('PendienteXusr', JSON.stringify(response.data).split('T00:00:00Z[-03:00]').join(''))
          self.PendienteXusr = JSON.parse(localStorage.getItem('PendienteXusr'))
          self.closeFSL()
        })
        .catch(function (error) {
          console.log(error)
          self.closeFSL()
        })
    },
    getProv: function (e) {
      var self = this
      self.openFSL()
      const url = 'http://' + this.getArea.ip + '/sc/getProv/params;tippro=' + this.s_tippro + ';artcod=' + this.s_artcod + ';empresa=' + this.s_empresa

      axios.interceptors.response.use(function (response) {
        return response
      }, function (error) {
        if (error.response.status === 401) {
          router.push('/login')
        }
        return Promise.reject(error)
      })
      axios.get(url, {
        dataType: 'json',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: self.getToken.token
        },
        mode: 'no-cors',
        credentials: 'include'
      })
        .then(function (response) {
          localStorage.setItem('prov', JSON.stringify(response.data).split('T00:00:00Z[-03:00]').join(''))
          self.proveedorHistorico = JSON.parse(localStorage.getItem('prov'))
          self.closeFSL()
        })
        .catch(function (error) {
          console.log('Catch getProv')
          console.log(error)
          self.closeFSL()
        })
    },
    getProvXempr: function (e) {
      var self = this
      self.openFSL()
      this.s_artcod = []
      const url = 'http://' + this.getArea.ip + '/sc/getProvxemp/params;empresa=' + this.s_empresa

      axios.interceptors.response.use(function (response) {
        return response
      }, function (error) {
        if (error.response.status === 401) {
          router.push('/login')
          router.push(self.getToken.token)
        }
        return Promise.reject(error)
      })
      axios.get(url, {
        dataType: 'json',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: self.getToken.token
        },
        mode: 'no-cors',
        credentials: 'include'
      })
        .then(function (response) {
          localStorage.setItem('provxemp', JSON.stringify(response.data).split('T00:00:00Z[-03:00]').join(''))
          self.proveedorxEmpresa = JSON.parse(localStorage.getItem('provxemp'))
          self.closeFSL()
        })
        .catch(function (error) {
          console.log('Catch getProv')
          console.log(error)
          self.closeFSL()
        })
    },
    getEmpresa: function (e) {
      var self = this
      self.openFSL()
      const url = 'http://' + this.getArea.ip + '/sc/getEmpresa/'

      axios.interceptors.response.use(function (response) {
        if (response.status === 401) {
          router.push('/login')
          router.push(self.getToken.token)
        }
        return response
      }, function (error) {
        if (error.response.status === 401) {
          router.push('/login')
          router.push(self.getToken.token)
        }
        return Promise.reject(error)
      })
      axios.get(url, {
        dataType: 'json',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: self.getToken.token
        },
        mode: 'no-cors',
        credentials: 'include'
      })
        .then(function (response) {
          localStorage.setItem('empresas', JSON.stringify(response.data).split('T00:00:00Z[-03:00]').join(''))
          self.empresas = JSON.parse(localStorage.getItem('empresas'))
          self.closeFSL()
        })
        .catch(function (error) {
          if (error.response.status === 401) {
            router.push('/login')
            router.push(self.getToken.token)
          }
          console.log('Catch getEmpresa')
          console.log(error)
          self.closeFSL()
        })
    },
    getProxScID: function (e) {
      if (this.PrepSolicitud.length === 0) {
        console.log('No precargo ningun pedido')
        this.errorMsg('Se debe cargar primero alguna solicitud')
        return
      }
      console.log('proximo SC ID')
      var self = this
      const url = 'http://' + this.getArea.ip + '/sc/getProxSoId/'
      axios.interceptors.response.use(function (response) {
        return response
      }, function (error) {
        if (error.response.status === 401) {
          router.push('/login')
          router.push(self.getToken.token)
        }
        return Promise.reject(error)
      })
      axios.get(url, {
        dataType: 'json',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: self.getToken.token
        },
        mode: 'no-cors',
        credentials: 'include'
      })
        .then(function (response) {
          localStorage.setItem('NroSolicitud', JSON.stringify(response.data).split('T00:00:00Z[-03:00]').join(''))
          self.NroSolicitud = JSON.parse(localStorage.getItem('NroSolicitud'))
          self.SC_ID = self.NroSolicitud[0].ID
          console.log('SC ID:' + self.SC_ID)
          self.consolidar()
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    consolidar () {
      // hacer un for que recorra la presolicitud y meta todo a Inser_SC
      this.continuar = true // Variable de control
      this.PrepSolicitud.forEach(solid => {
        if (this.continuar) {
          this.InsertaUnoaUno(solid)
          console.log(this.continuar)
        }
      })

      if (this.continuar) {
        this.successMsg('se registró la solicitud NRO:' + this.SC_ID)
        this.vaciarPreSolicitud()
        // borrar el listado de presolicitud
      }
      this.getPendienteXusr()
    },

    InsertaUnoaUno (e) {
      this.Ins_obs = e.observacion
      this.Ins_url = e.url
      this.Ins_artcod = e.artcod
      this.Ins_Tippro = e.tippro
      this.Ins_Empresa = e.empresa
      this.Ins_Cantid = e.cantidad
      this.Ins_Inexist = e.inexist
      this.ins_provee = e.proveedor
      this.ins_img = e.img
      this.ins_tstadj = e.tstadj
      this.ins_urg = e.urg
      this.Inser_SC()
    },
    Inser_SC () {
      var self = this
      self.openFSL()
      fetch(
        'http://' +
        this.getArea.ip +
        '/sc/params;id=' +
        self.SC_ID +
        ';id_itm=3;tippro=' +
        self.Ins_Tippro +
        ';artcod=' +
        self.Ins_artcod +
        ';cantid=' +
        self.Ins_Cantid +
        ';empresa=' +
        self.Ins_Empresa +
        ';observ=' +
        encodeURIComponent(self.Ins_obs) +
        ';url=' +
        encodeURIComponent(self.Ins_url) +
        ';inexist=' +
        encodeURIComponent(self.Ins_Inexist) +
        ';pro=' +
        self.ins_provee +
        ';urgencia=' +
        self.ins_urg +
        ';tstadj=' +
        self.ins_tstadj, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: self.getToken.token
          },
          body: JSON.stringify({
            imagen: this.ins_img
          })
        })
        .then(async response => {
          this.EstadoR = await response.json()
          if (this.EstadoR[0].user === 'ERROR') {
            this.continuar = false
            this.errorMsg('No se reaizó')
          }
          self.closeFSL()
        })
        .catch(error => {
          console.error('Mensaje de error: ', error)
          this.errorMsg(error)
          this.continuar = false
          self.closeFSL()
        })
    },
    borrarSolicitud (SC, ITM, fila) {
      console.log('borrarSolicitud')
      var self = this
      console.log('http://' + this.getArea.ip + '/sc/updScDel/params;SC_ID=' + SC + ';SC_ITM=' + ITM)
      self.openFSL()
      fetch('http://' + this.getArea.ip + '/sc/updScDel/params;SC_ID=' + SC + ';SC_ITM=' + ITM, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: self.getToken.token
        },
        body: JSON.stringify({
          // imagen: this.image
        })
      })
        .then(async response => {
          var resultado = await response.json()
          if (resultado[0].user === self.getToken.user) {
            this.PendienteXusr.splice(fila, 1)
            console.log('borra ok')
          } else {
            console.log('no borra')
            this.errorMsg('Solo puede borrar el usuario que lo creó')
          }
          self.closeFSL()
          console.log('fin borrarSolicitud')
        })
        .catch(error => {
          console.error('Mensaje de error: ', error)
          this.errorMsg(error)
          self.closeFSL()
        })
    },
    // Subir archivo ****************
    upload () {
      document.getElementById('uploadFile').click()
    },
    handleImage (e) {
      const selectedImage = e.target.files[0]
      this.fileSelected = selectedImage.name
      // console.log(selectedImage.name)
      this.createBase64Image(selectedImage)
    },
    createBase64Image (fileObject) {
      const reader = new FileReader()
      reader.onloadend = () => {
        this.image = reader.result
        console.log(this.image)
      }
      reader.readAsDataURL(fileObject)
    } /* ,
    checkOnline () {
      console.log('checkonline')
      return new Promise((resolve, reject) => {
        this.getIp.ip.then(ip => {
          axios.get('http://' + ip + '/sc/status/')
            .then(response => {
              if (response.status === 201) {
                console.log('IP: ' + ip + ' is online')
              }
              resolve(response) // Resolvemos la promesa con la respuesta
            })
            .catch(error => {
              console.log(error)
              this.online = true
              reject(error) // Rechazamos la promesa con el error
            })
        })
      })
    } */
  }
}
</script>
