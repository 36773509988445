<template>
  <div>
    <input type="text" v-model="TSTs" />
    <button @click="enviarTSTalPadre">Enviar a Test_F</button>
  </div>
</template>

<script>
export default {
  data () {
    return {
      TSTs: ''
    }
  },
  methods: {
    enviarTSTalPadre () {
      this.$emit('enviar-tst', this.TSTs)
    },
    recibirTSTdelPadre (TSTs) {
      this.TSTs = TSTs
      console.log('Componente Hijo' + this.TSTs)
    }
  }
}
</script>
