import { createStore } from 'vuex'
import createMultiTabState from 'vuex-multi-tab-state'

// var description = 'SC_V2'

export default createStore({
  state: {
    user: null,
    token: null,
    area: null,
    ip: null
  },
  mutations: {
    setUser (state, user) {
      state.user = user
    },
    setToken (state, token) {
      state.token = token
    },
    setArea (state, area) {
      state.area = area
    },
    setIp (state, ip) {
      state.ip = ip
    }
  },
  actions: {
  },
  modules: {
  },
  getters: {
    isLoggedIn (state) {
      return !!state.token
    },
    getToken (token) {
      return token
    },
    getArea (area) {
      return area
    },
    getUser (user) {
      return user
    },
    getIp (ip) {
      // console.log(ip.ip)
      return ip
    }
  },
  plugins: [
    createMultiTabState()
  ]
}
)
