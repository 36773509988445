<template>
  <h1>Cotizacion Nro: {{this.$route.params.id}}</h1>
<div v-if="isLoggedIn" class="container-fluid">
  <back/>
  <div class="col-md-2">
      <el-button type="primary" v-if="this.getArea.area==='it' || this.getArea.area==='COM'" plain><router-link to="/cotizacion">Armado de Cotizacion</router-link></el-button>
      <el-button type="warning" v-on:click="reloadPage" icon="el-icon-refresh">Recargar página</el-button>

  </div>
  <br>
  <el-row :gutter="20">
        <el-col :span="1"></el-col>
      <el-col :span="3">
        <el-card  class="box-card shadow" >
          <h3>Licitaciones vigentes</h3>
          <div v-for='(imp, key) in OpenCot' :key="key" >
          <el-button @click="RouteToCot(imp.ID)" >[{{imp.ID}}] {{imp.DATE}} </el-button>
        </div>
      </el-card>
      </el-col>
      <el-col :span="17">
  <br>
  <el-card  class="box-card shadow" >
    <h3>licitacion Nro</h3>
    <div class="row">
      <el-button type="success" @click="downloadExcel(CotSum)" icon="el-icon-download" >Descargar Excel</el-button>
          <table>
        <thead>
          <tr class="green">
            <th>Articulo</th>
            <th>Cantidad</th>
            <th>Observacion</th>
          </tr>
        </thead>
        <tbody>
              <tr v-for='(imp, key) in CotSum' :key="key">
                <td v-html="imp.articulo"></td>
                <td v-html="imp.cantidad"></td>
                <td v-html="imp.observacion" ></td>
              </tr>
            </tbody>
      </table>
    </div>
    <div class="row">
      <textarea name="ObsCompra" id="" cols="50" rows="3" v-if="this.getArea.area!=='COM'" disabled></textarea>
      <textarea name="ObsCompra" id="" cols="50" rows="3" v-if="this.getArea.area==='COM'"></textarea>
    </div>
    <br>
    <div class="row">
      <el-button type="success" v-if="this.getArea.area==='COM'" >Subir licitacion</el-button>
      <br>
      <el-button type="success" >Descargar licitacion</el-button>
      <br>
      <el-button type="success"  v-if="this.getArea.area==='COM'">Guardar cambios</el-button>
    </div>
    <br>
    <h5>Última modificacion: 15/03/23 15:32 </h5>
    <div class="col-md-2">
      <el-select v-model="s_familia"  placeholder="Autorizante">
                    <el-option
                      v-for="item in autorizante"
                      :key="item.usrid"
                      :label="item.usrid"
                      :value="item.usrid">
                    </el-option>
                  </el-select>
    </div>
  </el-card>
<br>
  <el-card  class="box-card shadow" >
    <h3>Observacion Directorio</h3>
    <div class="ror">
      <textarea name="ObsDirect" id="" cols="50" rows="3" v-if="this.getArea.area!=='COM'"></textarea>
      <textarea name="ObsDirect" id="" cols="50" rows="3" v-if="this.getArea.area==='COM'" disabled></textarea>
    </div>
    <div class="ror">
      <el-button type="success" >Guardar cambios</el-button>
      <el-button type="warning" >Subir Adjunto</el-button>
    </div>
    <br>
    <div class="ror"><h5>Última modificacion: 15/03/23 15:32</h5></div>
  </el-card>

</el-col>
    </el-row>
  </div>
  <br><br>
  <el-dialog
    v-model="this.imgVisible"
    title="IMAGEN"
    width="30%"
  >
    <div class="row">
        <vue-pdf-embed :source="imgData" name="plugin2" v-if="imgData.includes('pdf;')"/>
      <embed width="100%" height="100%" name="plugin2" :src="imgData" type="image/png" v-if="!imgData.includes('pdf;')">
      </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="this.imgVisible = false">Cerrar</el-button>
      </span>
    </template>
  </el-dialog>

</template>
<script>
import { mapGetters } from 'vuex'
import config from '../../public/config.json'
import axios from 'axios'
import router from '../router'
import shared from '../shared/shared'
import back from '../components/back.vue'
import * as XLSX from 'xlsx'
import { ref } from 'vue'
import VuePdfEmbed from 'vue-pdf-embed'

export default {
  extends: shared,
  components: {
    back, VuePdfEmbed
  },
  updated () {
    document.title = 'Licitacion #' + this.$route.params.id
  },
  mounted: function () {
    document.title = 'Licitacion #' + this.$route.params.id
    var self = this
    self.PrepSolicitud.length = 0
    self.Solicitud.length = 0
    self.Precot.length = 0
    self.GetOpenCot()
    self.getCotSum()
    // self.GetCotByUsr()
    // self.getFstCot()
  },
  beforeMount: function () {
    var self = this
    self.getPendienteXusr()
  },
  watch: {
  },
  data: function () {
    return {
      autorizante: [
        { usrid: 'Clemtente' },
        { usrid: 'Sandra' },
        { usrid: 'Carlos' },
        { usrid: 'Camila' }
      ],
      FstCot: [{ ID: '' }],
      COT_Fst_ID: '',
      ObserDialog: '',
      CierreDialog: '',
      CierreVisible: ref(false),
      imgVisible: ref(false),
      imgData: '',
      Cot_itm: '',
      dialogVisible: ref(false),
      INS_SC_ID: '',
      INS_SC_ITM: '',
      NroCotizacion: 1,
      tipproductos: [],
      Articulos: [],
      empresas: [],
      proveedor: [],
      s_item: 1,
      s_tippro: '',
      s_artcod: '',
      s_cantidad: '',
      s_empresa: '',
      s_proveedor: '',
      s_observacion: '',
      s_Url: '',
      s_familia: '',
      s_prodNoExiste: false,
      PrepSolicitud: [{
        NroSol: '',
        item: '',
        tippro: '',
        artcod: '',
        descrpProd: '',
        cantidad: '',
        empresa: '',
        proveedor: '',
        observacion: '',
        url: '',
        prodNoExiste: false
      }],
      PendienteXusr: [{
        ARTCOD: '1',
        CANTIDAD: 0,
        EMPRESA: 'comcam',
        ID_ITM: 0,
        ID_SO: 0,
        OBSERV: '',
        TIPPRO: '',
        URL: '',
        descrp: null,
        provee: '',
        Adj: '',
        usrid: ''
      }],
      OpenCot: [{
        ID: '',
        DATE: '',
        OBSERV: ''
      }],
      dialogVisibleGlobal: ref(false),
      ObservCOT_gral: '',
      CotSum: [{
        articulo: '',
        cantidad: '',
        observacion: ''
      }],
      CotByUsr: [{
        tipo: '',
        articulo: '',
        descrp: '',
        cantidad: '',
        empresa: '',
        observacion: '',
        urluserid: '',
        itm: '',
        obscot: '',
        adj: '',
        provee: ''
      }],
      Precot: [{
        ARTCOD: '',
        CANTIDAD: '',
        EMPRESA: '',
        ID_ITM: '',
        ID_SO: '',
        OBSERV: '',
        TIPPRO: '',
        URL: '',
        descrp: ''
      }],
      Solicitud: [{
        NroSol: '',
        item: '',
        tippro: '',
        artcod: '',
        cantidad: '',
        empresa: '',
        proveedor: '',
        observacion: '',
        prodNoExiste: false,
        cot: false
      }],
      Cotizacion: [{
        NroSol: '',
        item: '',
        NroCot: '',
        Estado: 'Agrupado'
      }],
      artnom: '',
      EstadoR: [],
      COT_ID: '',
      Ins_artcod: '1',
      Ins_Tippro: 'rpts',
      Ins_Empresa: 'comcam',
      Ins_Cantid: '',
      Ins_obs: '',
      Ins_url: ''
    }
  },
  computed: {
    ...mapGetters(['getToken']),
    ...mapGetters(['isLoggedIn']),
    ...mapGetters(['getArea'])
  },
  methods: {
    BorrarItm (itm, obscot) {
      console.log('BorraITM : ' + itm + ' ' + obscot)
      if (obscot !== null) {
        if (obscot.length > 10) {
          this.Cot_itm = itm
          this.bajaCotItm()
        }
      } else { this.errorMsg('Se debe grabar el motivo por el cual se quita el registro de la cotizacion') }
    },
    reloadPage () {
      location.reload()
    },
    descrpARTcod () {
      this.artnom = this.Articulos.find(art => art.artcod === this.s_artcod).descrp
    },
    RemoveCot (SC, Itm, index) {
      var self = this
      this.PendienteXusr.push(self.Precot.find(art => art.ID_SO === SC & art.ID_ITM === Itm))
      this.Precot.splice(index, 1)
    },
    VerObservacionCompras (itm, obs) {
      this.Cot_itm = itm
      this.ObserDialog = obs
      this.dialogVisible = true
    },
    GrabarObservacionCompras () {
      this.dialogVisible = ref(false)
      this.dialogVisible = false
      this.UpdateObsCot()
    },
    VerImg (img) {
      this.imgData = img
      this.imgVisible = ref(true)
    },
    CierreCot () {
      this.CierreVisible = ref(false)
    },
    reset () {
      /* this.CotByUsr.length = 0
      this.CotSum.length = 0
      Notification('reset') */

      var self = this
      /* self.PrepSolicitud.length = 0
      self.Solicitud.length = 0
      self.Precot.length = 0 */
      self.GetOpenCot()
    },
    RouteToCot (e) {
      this.reset()
      router.push('/licitacion/' + e)
    },
    downloadExcel (CotByUsr) {
      const ws = XLSX.utils.json_to_sheet(CotByUsr)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
      XLSX.writeFile(wb, 'table.xlsx')
    },
    vaciarSolicitud () {
      this.Solicitud.length = 0
    },
    /*
    cerrarCotizacion (estado) {
      // verificar si el campo observacion está con algun dato, sino pedir completarlo
      console.log('------------------------cerrarCotizacion-------------------')
      console.log(this.ObservCOT_gral)
      if (this.ObservCOT_gral !== 'undefined' && this.ObservCOT_gral !== null && this.ObservCOT_gral !== '') {
        this.updCotCls(estado)
        this.RouteToCot(this.COT_Fst_ID)
      } else {
        this.errorMsg('Se debe registrar el motivo del cierre en la Observacion Global de la Cotizacion')
      }
    }, */
    openFSL () {
      this.loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
    },
    closeFSL () {
      this.loading.close()
    },
    getCotSum: function (e) {
      var self = this
      self.openFSL()
      this.s_artcod = []
      const url = 'http://' + config.ip + '/sc/getCotSum/params;id=' + this.$route.params.id

      axios.interceptors.response.use(function (response) {
        return response
      }, function (error) {
        if (error.response.status === 401) {
          router.push('/login')
        }
        return Promise.reject(error)
      })
      axios.get(url, {
        dataType: 'json',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: self.getToken.token
        },
        mode: 'no-cors',
        credentials: 'include'
      })
        .then(function (response) {
          localStorage.setItem('Cotsum', JSON.stringify(response.data).split('T00:00:00Z[-03:00]').join(''))
          self.CotSum = JSON.parse(localStorage.getItem('Cotsum'))
          self.closeFSL()
          self.GetCotByUsr()
        })
        .catch(function (error) {
          console.log(error)
          self.closeFSL()
        })
    },
    UpdateObsCot (self2) {
      var self = this
      self2.dialogVisible = false
      console.log('http://' + config.ip + '/sc/updObsCot/params;cot_id=' + this.$route.params.id + ';cot_itm=' + this.Cot_itm + ';observ=' + this.ObserDialog)
      self.openFSL()
      fetch('http://' + config.ip + '/sc/updObsCot/params;cot_id=' + this.$route.params.id + ';cot_itm=' + this.Cot_itm + ';observ=' + encodeURIComponent(this.ObserDialog), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: self.getToken.token
        },
        body: JSON.stringify({
          // imagen: this.image
        })
      })
        .then(async response => {
          this.EstadoR = await response.json()
          if (this.EstadoR[0].user === self.getToken.user) {
          }
          self.GetCotByUsr()
          self.closeFSL()
        })
        .catch(error => {
          console.error('Mensaje de error: ', error)
          this.errorMsg(error)
          self.closeFSL()
        })
    },
    /* getFstCot: function (e) {
      console.log('proximo COT ID')
      var self = this
      const url = 'http://' + config.ip + '/sc/getFstCot/'
      axios.interceptors.response.use(function (response) {
        return response
      }, function (error) {
        if (error.response.status === 401) {
          router.push('/login')
        }
        return Promise.reject(error)
      })
      axios.get(url, {
        dataType: 'json',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: self.getToken.token
        },
        mode: 'no-cors',
        credentials: 'include'
      })
        .then(function (response) {
          localStorage.setItem('FstCot', JSON.stringify(response.data).split('T00:00:00Z[-03:00]').join(''))
          self.FstCot = JSON.parse(localStorage.getItem('FstCot'))
          console.log('cotizacion con el menor indice')
          console.log(self.FstCot[0].ID)
          self.COT_Fst_ID = self.FstCot[0].ID
        })
        .catch(function (error) {
          console.log(error)
        })
    }, */
    updCotCls (estado) {
      var self = this
      self.openFSL()
      fetch('http://' + config.ip + '/sc/updCotCls/params;cot_id=' + this.$route.params.id + ';observ=' + estado, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: self.getToken.token
        },
        body: JSON.stringify({
          // imagen: this.image
        })
      })
        .then(async response => {
          this.EstadoR = await response.json()
          if (this.EstadoR[0].user === self.getToken.user) {
          }
          self.GetCotByUsr()
          self.closeFSL()
        })
        .catch(error => {
          console.error('Mensaje de error: ', error)
          this.errorMsg(error)
          self.closeFSL()
        })
    },
    UpdateObsCotGlobal (self2) {
      var self = this
      self2.dialogVisibleGlobal = false
      console.log('http://' + config.ip + '/sc/updObsCotGlobal/params;cot_id=' + this.$route.params.id + ';observ=' + this.ObserDialog)
      self.openFSL()
      fetch('http://' + config.ip + '/sc/updObsCotGlobal/params;cot_id=' + this.$route.params.id + ';observ=' + encodeURIComponent(this.ObservCOT_gral), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: self.getToken.token
        },
        body: JSON.stringify({
          // imagen: this.image
        })
      })
        .then(async response => {
          this.EstadoR = await response.json()
          if (this.EstadoR[0].user === self.getToken.user) {
          }
          self.GetCotByUsr()
          self.closeFSL()
        })
        .catch(error => {
          console.error('Mensaje de error: ', error)
          this.errorMsg(error)
          self.closeFSL()
        })
    },
    bajaCotItm () {
      var self = this
      console.log('http://' + config.ip + '/sc/updObsCot/params;cot_id=' + this.$route.params.id + ';cot_itm=' + this.Cot_itm)
      self.openFSL()
      fetch('http://' + config.ip + '/sc/bajaCotItm/params;cot_id=' + this.$route.params.id + ';cot_itm=' + this.Cot_itm, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: self.getToken.token
        },
        body: JSON.stringify({
          // imagen: this.image
        })
      })
        .then(async response => {
          this.EstadoR = await response.json()
          if (this.EstadoR[0].user === self.getToken.user) {
          }
          self.GetCotByUsr()
          self.closeFSL()
        })
        .catch(error => {
          console.error('Mensaje de error: ', error)
          this.errorMsg(error)
          self.closeFSL()
        })
    },
    getPendienteXusr: function (e) {
      var self = this
      self.openFSL()
      this.s_artcod = []
      const url = 'http://' + config.ip + '/sc/getPendiente/params;usrid=' + this.getToken.user + ';byusr=N'

      axios.interceptors.response.use(function (response) {
        return response
      }, function (error) {
        if (error.response.status === 401) {
          router.push('/login')
        }
        return Promise.reject(error)
      })
      axios.get(url, {
        dataType: 'json',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: self.getToken.token
        },
        mode: 'no-cors',
        credentials: 'include'
      })
        .then(function (response) {
          localStorage.setItem('PendienteXusr', JSON.stringify(response.data).split('T00:00:00Z[-03:00]').join(''))
          self.PendienteXusr = JSON.parse(localStorage.getItem('PendienteXusr'))
          self.closeFSL()
        })
        .catch(function (error) {
          console.log(error)
          self.closeFSL()
        })
    },
    GetOpenCot: function (e) {
      console.log('getOpenCot')
      var self = this
      const url = 'http://' + config.ip + '/sc/getOpenCot/'
      axios.interceptors.response.use(function (response) {
        return response
      }, function (error) {
        if (error.response.status === 401) {
          router.push('/login')
        }
        return Promise.reject(error)
      })
      axios.get(url, {
        dataType: 'json',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: self.getToken.token
        },
        mode: 'no-cors',
        credentials: 'include'
      })
        .then(function (response) {
          localStorage.setItem('OpenCot', JSON.stringify(response.data).split('T00:00:00Z[-03:00]').join(''))
          self.OpenCot = JSON.parse(localStorage.getItem('OpenCot'))
          const cotid = self.$route.params.id
          console.log(cotid)
          self.ObservCOT_gral = ''
          self.ObservCOT_gral = self.OpenCot.find(cot => cot.ID === parseInt(cotid)).OBSERV
          console.log(self.ObservCOT_gral)
          self.getCotSum()
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    GetCotByUsr: function (e) {
      console.log('getCotById')
      var self = this
      const url = 'http://' + config.ip + '/sc/getCotById/params;id=' + this.$route.params.id
      axios.interceptors.response.use(function (response) {
        return response
      }, function (error) {
        if (error.response.status === 401) {
          router.push('/login')
        }
        return Promise.reject(error)
      })
      axios.get(url, {
        dataType: 'json',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: self.getToken.token
        },
        mode: 'no-cors',
        credentials: 'include'
      })
        .then(function (response) {
          localStorage.setItem('CotByUsr', JSON.stringify(response.data).split('T00:00:00Z[-03:00]').join(''))
          self.CotByUsr = JSON.parse(localStorage.getItem('CotByUsr'))
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    consolidar () {
      // hacer un for que recorra la presolicitud y meta todo a Inser_COT
      this.Precot.forEach(solid => this.functionTest(solid))
      this.successMsg('se registró la Cotizacion NRO:' + this.COT_ID)
      // borrar el listado de presolicitud
      this.vaciarPreCotizacion()
      this.getPendienteXusr()
    },
    functionTest (e) {
      this.INS_SC_ID = e.ID_SO
      this.INS_SC_ITM = e.ID_ITM
      this.Inser_COT()
    },
    Inser_COT () {
      var self = this
      self.openFSL()
      // http://34.69.86.51:8080/sc/INS_COT/params;cot_id=29;sc_id=8;sc_itm=7;
      fetch('http://' + config.ip + '/sc/INS_COT/params;cot_id=' + self.COT_ID + ';sc_id=' + self.INS_SC_ID + ';sc_itm=' + self.INS_SC_ITM, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: self.getToken.token
        },
        body: JSON.stringify({
        })
      })
        .then(async response => {
          this.EstadoR = await response.json()
          self.closeFSL()
        })
        .catch(error => {
          console.error('Mensaje de error: ', error)
          this.errorMsg(error)
          self.closeFSL()
        })
    }

  }
}
</script>
<style>
.el-input--suffix .el-input__inner {
    margin-bottom: 15px;
}
.el-button--primary.is-plain {
  margin-bottom: 15px;
  border-radius: 20px;
  background: linear-gradient(to bottom, #007aff, #0060ff);
  color: white;
  padding: 10px 20px;
  font-size: 14px;
  border: none;
}
a {
    color: white;
    text-decoration: underline;
}
</style>
