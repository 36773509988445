export default {
  getConfig: async function () {
    try {
      console.log('consultando IP...')
      const description = 'SC_V2' // valor del parámetro "description"
      const pin = 'bYmS8iMxmMG0v0Qj' // valor del parámetro "pin"
      const apiUrl = `https://grupopenna.com.ar/ip/ip.php?description=${description}&pin=${pin}`
      const response = await fetch(apiUrl)
      const data = await response.json()
      console.log(data.ip)
      return data.ip
    } catch (error) {
      console.error(error)
    }
  }
}
