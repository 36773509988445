<template>
  <div>
    <input type="text" v-model="TSTs" />
    <button @click="enviarTSTaTestC">Enviar a Test_C</button>
    <test-c
      @enviar-tst="recibirTSTdelHijo"
      ref="testCComponent">
    </test-c>
  </div>
</template>

<script>
import TestC from './test_H.vue'

export default {
  components: {
    TestC
  },
  data () {
    return {
      TSTs: ''
    }
  },
  methods: {
    enviarTSTaTestC () {
      this.$refs.testCComponent.recibirTSTdelPadre(this.TSTs)
    },
    recibirTSTdelHijo (TSTs) {
      this.TSTs = TSTs
      console.log('Componente Padre' + this.TSTs)
    }
  }
}
</script>
