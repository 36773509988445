<template>
  <h1>Cotizacion del proveedor {{this.$route.params.id}}</h1>
<div v-if="isLoggedIn" class="container-fluid">
  <back/>
  <div class="col-md-2">
      <el-button type="primary" plain><router-link to="/cotizacion">Armado de Cotizacion</router-link></el-button>
  </div>
  <div class="col-md-2">
      <el-button type="primary" plain>Saludo al Proveedor</el-button>
  </div>
  <br>
  <el-row :gutter="20">
        <el-col :span="1"></el-col>
      <el-col :span="3">
          <h3>Cotizaciones Abiertas</h3>
          <div v-for='(imp, key) in OpenCot' :key="key" >
          <el-button @click="RouteToCot(imp.ID)" >[{{imp.ID}}] {{imp.DATE}} </el-button>
        </div>
      </el-col>
      <el-col :span="17">
        <h3>Solicitudes Detalladas</h3>
        <table>
      <thead>
        <tr class="green">
          <th>Articulo</th>
          <th>Cantidad</th>
          <th>Precio</th>
          <th>Observ(Solicitante)</th>
        </tr>
      </thead>
      <tbody>
            <tr v-for='(imp, key) in CotByUsr' :key="key">
              <td v-html="imp.descrp"></td>
              <td v-html="imp.cantidad"></td>
              <td><el-input type='text' v-model="imp.cantidad" placeholder= 'imp.cantidad' /></td>
              <td v-html="imp.observacion"></td>
              <td>
                <router-link v-bind:to="'/TktInfo/' + key">Anular</router-link>
              </td>
            </tr>
          </tbody>
    </table>
    <h3>Solicitudes Agrupadas</h3>
        <table>
      <thead>
        <tr class="green">
          <th>Articulo</th>
          <th>Cantidad</th>
          <th>Observacion</th>
        </tr>
      </thead>
      <tbody>
            <tr v-for='(imp, key) in CotSum' :key="key">
              <td v-html="imp.articulo"></td>
              <td v-html="imp.cantidad"></td>
              <td v-html="imp.observacion" ></td>
            </tr>
          </tbody>
    </table>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import config from '../../public/config.json'
import axios from 'axios'
import router from '../router'
import shared from '../shared/shared'
import back from '../components/back.vue'

export default {
  extends: shared,
  components: {
    back
  },
  mounted: function () {
    var self = this
    self.PrepSolicitud.length = 0
    self.Solicitud.length = 0
    self.Precot.length = 0
    self.GetOpenCot()
    self.getCotSum()
    self.GetCotByUsr()
  },
  beforeMount: function () {
    var self = this
    self.getPendienteXusr()
  },
  watch: {
  },
  data: function () {
    return {
      INS_SC_ID: '',
      INS_SC_ITM: '',
      NroCotizacion: 1,
      tipproductos: [],
      Articulos: [],
      empresas: [],
      proveedor: [],
      s_item: 1,
      s_tippro: '',
      s_artcod: '',
      s_cantidad: '',
      s_empresa: '',
      s_proveedor: '',
      s_observacion: '',
      s_Url: '',
      s_familia: '',
      s_prodNoExiste: false,
      PrepSolicitud: [{
        NroSol: '',
        item: '',
        tippro: '',
        artcod: '',
        descrpProd: '',
        cantidad: '',
        empresa: '',
        proveedor: '',
        observacion: '',
        url: '',
        prodNoExiste: false
      }],
      PendienteXusr: [{
        ARTCOD: '1',
        CANTIDAD: 0,
        EMPRESA: 'comcam',
        ID_ITM: 0,
        ID_SO: 0,
        OBSERV: '',
        TIPPRO: '',
        URL: '',
        descrp: null
      }],
      OpenCot: [{
        ID: '',
        DATE: ''
      }],
      CotSum: [{
        articulo: '',
        cantidad: '',
        observacion: ''
      }],
      CotByUsr: [{
        articulo: '',
        descrp: '',
        cantidad: '',
        empresa: '',
        observacion: '',
        urluserid: ''
      }],
      Precot: [{
        ARTCOD: '',
        CANTIDAD: '',
        EMPRESA: '',
        ID_ITM: '',
        ID_SO: '',
        OBSERV: '',
        TIPPRO: '',
        URL: '',
        descrp: ''
      }],
      Solicitud: [{
        NroSol: '',
        item: '',
        tippro: '',
        artcod: '',
        cantidad: '',
        empresa: '',
        proveedor: '',
        observacion: '',
        prodNoExiste: false,
        cot: false
      }],
      Cotizacion: [{
        NroSol: '',
        item: '',
        NroCot: '',
        Estado: 'Agrupado'
      }],
      artnom: '',
      EstadoR: [],
      COT_ID: '',
      Ins_artcod: '1',
      Ins_Tippro: 'rpts',
      Ins_Empresa: 'comcam',
      Ins_Cantid: '',
      Ins_obs: '',
      Ins_url: ''
    }
  },
  computed: {
    ...mapGetters(['getToken']),
    ...mapGetters(['isLoggedIn'])
  },
  methods: {
    descrpARTcod () {
      this.artnom = this.Articulos.find(art => art.artcod === this.s_artcod).descrp
    },
    addNewCot (SC, Itm, index) {
      this.Precot.push(this.PendienteXusr.find(art => art.ID_SO === SC & art.ID_ITM === Itm))
      this.PendienteXusr.splice(index, 1)
      // http://34.69.86.51:8080/sc/INS_COT/params;cot_id=29;COT_ID=8;sc_itm=7;
    },
    RemoveCot (SC, Itm, index) {
      var self = this
      this.PendienteXusr.push(self.Precot.find(art => art.ID_SO === SC & art.ID_ITM === Itm))
      this.Precot.splice(index, 1)
    },
    addPreSolicitud () {
      this.PrepSolicitud.push(
        {
          NroSol: this.NroSolicitud,
          item: this.s_item,
          tippro: this.s_tippro,
          artcod: this.s_artcod,
          descrpProd: this.artnom,
          cantidad: this.s_cantidad,
          empresa: this.s_empresa,
          proveedor: this.s_proveedor,
          observacion: this.s_observacion,
          prodNoExiste: this.s_prodNoExiste,
          url: this.s_Url
        }
      )
      this.s_item = this.s_item + 1
    },
    RouteToCot (e) {
      this.getCotSum()
      this.GetCotByUsr()
      router.push('/cotinfo/' + e)
    },
    SC_PendienteXusrXusuario () {
      /* *********************************************************************************************USP_SC_prevXusr seguir con esto */
    },
    BorrarItm (item) {
      // aca borrar solo el item mencionado
    },
    NuevaCotizacion () {
      this.ticketF = 1
    },
    vaciarPreCotizacion () {
      this.Precot.length = 0
    },
    vaciarSolicitud () {
      this.Solicitud.length = 0
    },
    openFSL () {
      this.loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
    },
    closeFSL () {
      this.loading.close()
    },
    getTippro: function (e) {
      var self = this
      self.openFSL()
      const url = 'http://' + config.ip + '/sc/getTippro/'

      axios.interceptors.response.use(function (response) {
        return response
      }, function (error) {
        if (error.response.status === 401) {
          router.push('/login')
        }
        return Promise.reject(error)
      })
      axios.get(url, {
        dataType: 'json',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: self.getToken.token
        },
        mode: 'no-cors',
        credentials: 'include'
      })
        .then(function (response) {
          localStorage.setItem('tippro', JSON.stringify(response.data).split('T00:00:00Z[-03:00]').join(''))
          self.tipproductos = JSON.parse(localStorage.getItem('tippro'))
          self.closeFSL()
        })
        .catch(function (error) {
          console.log('Catch getTippro')
          console.log(error)
          self.closeFSL()
        })
    },
    getCotSum: function (e) {
      var self = this
      self.openFSL()
      this.s_artcod = []
      const url = 'http://' + config.ip + '/sc/getCotSum/params;id=' + this.$route.params.id

      axios.interceptors.response.use(function (response) {
        return response
      }, function (error) {
        if (error.response.status === 401) {
          router.push('/login')
        }
        return Promise.reject(error)
      })
      axios.get(url, {
        dataType: 'json',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: self.getToken.token
        },
        mode: 'no-cors',
        credentials: 'include'
      })
        .then(function (response) {
          localStorage.setItem('Cotsum', JSON.stringify(response.data).split('T00:00:00Z[-03:00]').join(''))
          self.CotSum = JSON.parse(localStorage.getItem('Cotsum'))
          self.closeFSL()
        })
        .catch(function (error) {
          console.log(error)
          self.closeFSL()
        })
    },
    getPendienteXusr: function (e) {
      var self = this
      self.openFSL()
      this.s_artcod = []
      const url = 'http://' + config.ip + '/sc/getPendiente/params;usrid=' + this.getToken.user + ';byusr=N'

      axios.interceptors.response.use(function (response) {
        return response
      }, function (error) {
        if (error.response.status === 401) {
          router.push('/login')
        }
        return Promise.reject(error)
      })
      axios.get(url, {
        dataType: 'json',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: self.getToken.token
        },
        mode: 'no-cors',
        credentials: 'include'
      })
        .then(function (response) {
          localStorage.setItem('PendienteXusr', JSON.stringify(response.data).split('T00:00:00Z[-03:00]').join(''))
          self.PendienteXusr = JSON.parse(localStorage.getItem('PendienteXusr'))
          self.closeFSL()
        })
        .catch(function (error) {
          console.log(error)
          self.closeFSL()
        })
    },
    GetOpenCot: function (e) {
      console.log('proximo COT ID')
      var self = this
      const url = 'http://' + config.ip + '/sc/getOpenCot/'
      axios.interceptors.response.use(function (response) {
        return response
      }, function (error) {
        if (error.response.status === 401) {
          router.push('/login')
        }
        return Promise.reject(error)
      })
      axios.get(url, {
        dataType: 'json',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: self.getToken.token
        },
        mode: 'no-cors',
        credentials: 'include'
      })
        .then(function (response) {
          localStorage.setItem('OpenCot', JSON.stringify(response.data).split('T00:00:00Z[-03:00]').join(''))
          self.OpenCot = JSON.parse(localStorage.getItem('OpenCot'))
          console.log(self.OpenCot)
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    GetCotByUsr: function (e) {
      console.log('proximo COT ID')
      var self = this
      const url = 'http://' + config.ip + '/sc/getCotById/params;id=' + this.$route.params.id
      axios.interceptors.response.use(function (response) {
        return response
      }, function (error) {
        if (error.response.status === 401) {
          router.push('/login')
        }
        return Promise.reject(error)
      })
      axios.get(url, {
        dataType: 'json',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: self.getToken.token
        },
        mode: 'no-cors',
        credentials: 'include'
      })
        .then(function (response) {
          localStorage.setItem('CotByUsr', JSON.stringify(response.data).split('T00:00:00Z[-03:00]').join(''))
          self.CotByUsr = JSON.parse(localStorage.getItem('CotByUsr'))
          console.log(self.CotByUsr)
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    consolidar () {
      // hacer un for que recorra la presolicitud y meta todo a Inser_COT
      this.Precot.forEach(solid => this.functionTest(solid))
      this.successMsg('se registró la Cotizacion NRO:' + this.COT_ID)
      // borrar el listado de presolicitud
      this.vaciarPreCotizacion()
      this.getPendienteXusr()
    },
    functionTest (e) {
      this.INS_SC_ID = e.ID_SO
      this.INS_SC_ITM = e.ID_ITM
      this.Inser_COT()
    },
    Inser_COT () {
      var self = this
      self.openFSL()
      // http://34.69.86.51:8080/sc/INS_COT/params;cot_id=29;sc_id=8;sc_itm=7;
      fetch('http://' + config.ip + '/sc/INS_COT/params;cot_id=' + self.COT_ID + ';sc_id=' + self.INS_SC_ID + ';sc_itm=' + self.INS_SC_ITM, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: self.getToken.token
        },
        body: JSON.stringify({
        })
      })
        .then(async response => {
          this.EstadoR = await response.json()
          self.closeFSL()
        })
        .catch(error => {
          console.error('Mensaje de error: ', error)
          this.errorMsg(error)
          self.closeFSL()
        })
    }

  }
}
</script>
