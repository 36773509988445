<template>
  <h1>Armado de Cotizacion</h1>
<div v-if="isLoggedIn" class="container-fluid">
  <back/>
  <div class="col-md-2">
      <el-button type="warning" v-on:click="reloadPage" icon="el-icon-refresh">Recargar Página</el-button>
  </div>
  <div>

  </div>
  <el-card  class="box-card shadow">
  <div class='row'>
    <h5>Agrupador de Cotizacion</h5>
    <br>
    <table>
      <thead>
        <tr class="green">
          <th>Nro</th>
          <th>Tipo</th>
          <th>Articulo</th>
          <th>Cantidad</th>
          <th>Empresa</th>
          <th>proveedor</th>
          <th>Observacion</th>
          <th>Urgencia</th>
          <th>URL</th>
          <th>Adjunto</th>
          <th>Quitar</th>
        </tr>
      </thead>
      <tbody>
            <tr v-for='(imp, i) in Precot' :key='i'>
              <td v-html="imp.ID_ITM"></td>
              <td v-html="imp.TIPPRO"></td>
              <td v-html="imp.descrp"></td>
              <td v-html="imp.CANTIDAD"></td>
              <td v-html="imp.EMPRESA"></td>
              <td v-html="imp.provee"></td>
              <td v-html="imp.OBSERV"></td>
              <td>
                <span :class="{
                  'red-text': imp.urg === 'Urg',
                  'yellow-text': imp.urg === '72Hs',
                  'green-text': imp.urg === '10D',
                  'cyan-text': imp.urg === '30D'
                }">{{imp.urg}}</span>
              </td>
              <td ><a :href="imp.url" target="_blank" >{{imp.url}}</a></td>
              <td ><el-button type="primary" size="mini" icon="el-icon-picture" @click="VerImg(imp.Adj)" circle v-if="imp.Adj.length!==0"></el-button></td>
              <td ><el-button type="danger"  @click="RemoveCot(imp.ID_SO,imp.ID_ITM,i)" plain>X</el-button></td>
            </tr>
          </tbody>
    </table>
  </div>
    <div class="col-md-2">
      <el-button type="primary"  @click="getProxCot_Id()" plain>Guardar Cotizacion</el-button>
  </div>
  <div class="col-md-2">
      <el-button type="primary"  @click="vaciarPreCotizacion" plain>Vaciar listado pre orden</el-button>
  </div>
</el-card>
    <br>
<el-card  class="box-card shadow">
  <h3>Solicitudes Pendiente a cotizar</h3>
  <div class='row'>
    <div class="col-md-6">
        <div class="form-inline">
          <el-input
            placeholder="Filtrar tipo, artículo, empresa u observacion  🔍"
            v-model="this.search"
            @change="tablaPendiente"
          />
        </div>
      </div>
    <br>
    <table
          id="table_id"
          data-filter-control="true"
          data-search="true">
      <thead>
        <tr class="green">
          <th>Nro Sol</th>
          <th>Item</th>
          <th>Tipo</th>
          <th>Articulo</th>
          <th>Cantidad</th>
          <th>Empresa</th>
          <th>proveedor</th>
          <th>Observacion</th>
          <th>Usuario</th>
          <th>URL</th>
          <th>Adjunto</th>
          <th>Nivel de urgencia</th>
          <th>Fecha</th>
          <th>Cotizar</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for='(imp, i) in tablaPendiente' :key='i'>
              <td v-html="imp.ID_SO"></td>
              <td v-html="imp.ID_ITM"></td>
              <td v-html="imp.TIPPRO"></td>
              <td v-html="imp.descrp"></td>
              <td v-html="imp.CANTIDAD"></td>
              <td v-html="imp.EMPRESA"></td>
              <td v-html="imp.provee.replace('PROVEEDOR GENERICO NO USAR |','').slice(0, 20)"></td>
              <td v-html="imp.OBSERV.slice(0, 50)"></td>
              <td v-html="imp.usrid"></td>
              <td ><a :href="imp.URL" target="_blank" v-truncate:[limit]="imp.URL"></a></td>
              <td ><el-button type="primary" size="mini" icon="el-icon-picture" @click="VerImg(imp.Adj)" circle v-if="imp.Adj.length!==0"></el-button></td>
              <td>
                <span :class="{
                  'red-text': imp.urg === 'Urg',
                  'yellow-text': imp.urg === '72Hs',
                  'green-text': imp.urg === '10D',
                  'cyan-text': imp.urg === '30D'
                }">{{imp.urg}}</span>
              </td>
              <td>
                <span :class="{
                  'green-text': imp.fecha === this.today,
                  'yellow-text': imp.fecha === yesterday,
                  'red-text': imp.fecha > yesterday
                }">{{imp.fecha}}</span>
              </td>
              <td ><el-button type="primary"  @click="addNewCot(imp.ID_SO,imp.ID_ITM,i)" plain>Agregar</el-button></td>
            </tr>
          </tbody>
    </table>
  </div>
</el-card>
<br>
</div>
  <el-dialog
    v-model="this.imgVisible"
    title="IMAGEN"
    width="30%"
  >
    <div class="row">
      <vue-pdf-embed :source="imgData" name="plugin2" v-if="imgData.includes('pdf;')"/>
      <embed width="100%" height="100%" name="plugin2" :src="imgData" type="image/png" v-if="!imgData.includes('pdf;')">
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="this.imgVisible = false">Cerrar</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script>
import { mapGetters } from 'vuex'
// import config from '../../public/config.json'
import axios from 'axios'
import router from '../router'
import shared from '../shared/shared'
import back from '../components/back.vue'
import { ref } from 'vue'
import VuePdfEmbed from 'vue-pdf-embed'

export default {
  extends: shared,
  components: {
    back, VuePdfEmbed
  },
  mounted: function () {
    document.title = 'Agrupador de Solicitudes'
    var self = this
    console.log(self.getArea.area)
    self.getFstCot()
    self.PrepSolicitud.length = 0
    self.Solicitud.length = 0
    self.Precot.length = 0
  },
  beforeMount: function () {
    var self = this
    self.getPendienteXusr()
  },
  watch: {
    search (newVal) {
      localStorage.setItem('search', newVal)
    }
  },
  data: function () {
    return {
      // ip: '34.67.86.78:8080',
      today: new Date().toLocaleDateString('es-ES'),
      yesterday: (() => {
        const date = new Date()
        date.setDate(date.getDate() - 1)
        return date.toLocaleDateString('es-ES')
      })(),
      search: '',
      imgVisible: ref(false),
      imgData: '',
      INS_SC_ID: '',
      INS_SC_ITM: '',
      COT_Fst_ID: '1',
      NroCotizacion: 1,
      tipproductos: [],
      Articulos: [],
      empresas: [],
      proveedor: [],
      s_item: 1,
      s_tippro: '',
      s_artcod: '',
      s_cantidad: '',
      s_empresa: '',
      s_proveedor: '',
      s_observacion: '',
      s_Url: '',
      s_familia: '',
      s_prodNoExiste: false,
      PrepSolicitud: [{
        NroSol: '',
        item: '',
        tippro: '',
        artcod: '',
        descrpProd: '',
        cantidad: '',
        empresa: '',
        proveedor: '',
        observacion: '',
        url: '',
        prodNoExiste: false
      }],
      PendienteXusr: [{
        ARTCOD: '',
        CANTIDAD: 0,
        EMPRESA: '',
        ID_ITM: 0,
        ID_SO: 0,
        OBSERV: '',
        TIPPRO: '',
        URL: '',
        descrp: null,
        provee: '',
        Adj: '',
        usrid: '',
        urg: '',
        fecha: ''
      }],
      Precot: [{
        ARTCOD: '',
        CANTIDAD: '',
        EMPRESA: '',
        ID_ITM: '',
        ID_SO: '',
        OBSERV: '',
        TIPPRO: '',
        URL: '',
        descrp: '',
        Adj: '',
        Urg: ''
      }],
      Solicitud: [{
        NroSol: '',
        item: '',
        tippro: '',
        artcod: '',
        cantidad: '',
        empresa: '',
        proveedor: '',
        observacion: '',
        prodNoExiste: false,
        cot: false
      }],
      Cotizacion: [{
        NroSol: '',
        item: '',
        NroCot: '',
        Estado: 'Agrupado'
      }],
      artnom: '',
      EstadoR: [],
      COT_ID: '',
      Ins_artcod: '1',
      Ins_Tippro: 'rpts',
      Ins_Empresa: 'comcam',
      Ins_Cantid: '',
      Ins_obs: '',
      Ins_url: '',
      limit: 10
    }
  },
  directives: {
    truncate: {
      bind: function (el, binding) {
        const limit = binding.value
        const content = el.innerText
        let truncated = content.substring(0, limit)
        if (content.length > limit) {
          truncated += '...'
        }
        el.innerHTML = truncated
      }
    }
  },
  computed: {
    tablaPendiente () {
      // return this.PendienteXusr.filter(imp => imp.descrp.includes(this.search))
      if (this.search.length > 0) {
        console.log('toLowerCase')
        return this.PendienteXusr.filter(imp =>
          imp.descrp.toLowerCase().includes(this.search.toLowerCase()) ||
        imp.TIPPRO.toLowerCase().includes(this.search.toLowerCase()) ||
        imp.EMPRESA.toLowerCase().includes(this.search.toLowerCase()) ||
        imp.OBSERV.toLowerCase().includes(this.search.toLowerCase())
        ) || {}
      } else {
        return this.PendienteXusr
      }
    },
    ...mapGetters(['getToken']),
    ...mapGetters(['isLoggedIn']),
    ...mapGetters(['getArea'])
  },
  methods: {
    descrpARTcod () {
      this.artnom = this.Articulos.find(art => art.artcod === this.s_artcod).descrp
    },
    addNewCot (SC, Itm, index) {
      this.Precot.push(this.PendienteXusr.find(art => art.ID_SO === SC & art.ID_ITM === Itm))
      this.PendienteXusr.splice(index, 1)
      // http://34.69.86.51:8080/sc/INS_COT/params;cot_id=29;COT_ID=8;sc_itm=7;
    },
    RemoveCot (SC, Itm, index) {
      var self = this
      this.PendienteXusr.push(self.Precot.find(art => art.ID_SO === SC & art.ID_ITM === Itm))
      this.Precot.splice(index, 1)
    },
    addPreSolicitud () {
      this.PrepSolicitud.push(
        {
          NroSol: this.NroSolicitud,
          item: this.s_item,
          tippro: this.s_tippro,
          artcod: this.s_artcod,
          descrpProd: this.artnom,
          cantidad: this.s_cantidad,
          empresa: this.s_empresa,
          proveedor: this.s_proveedor,
          observacion: this.s_observacion,
          prodNoExiste: this.s_prodNoExiste,
          url: this.s_Url
        }
      )
      this.s_item = this.s_item + 1
    },
    reloadPage () {
      location.reload()
    },
    VerImg (img) {
      this.imgData = img
      this.imgVisible = true
    },
    SC_PendienteXusrXusuario () {
      /* *********************************************************************************************USP_SC_prevXusr seguir con esto */
    },
    BorrarItm (item) {
      // aca borrar solo el item mencionado
    },
    NuevaCotizacion () {
      this.ticketF = 1
    },
    vaciarPreCotizacion () {
      this.Precot.length = 0
      this.getPendienteXusr()
    },
    openFSL () {
      this.loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
    },
    closeFSL () {
      this.loading.close()
    },
    getTippro: function (e) {
      var self = this
      self.openFSL()
      const url = 'http://' + this.getArea.ip + '/sc/getTippro/'

      axios.interceptors.response.use(function (response) {
        return response
      }, function (error) {
        if (error.response.status === 401) {
          router.push('/login')
        }
        return Promise.reject(error)
      })
      axios.get(url, {
        dataType: 'json',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: self.getToken.token
        },
        mode: 'no-cors',
        credentials: 'include'
      })
        .then(function (response) {
          localStorage.setItem('tippro', JSON.stringify(response.data).split('T00:00:00Z[-03:00]').join(''))
          self.tipproductos = JSON.parse(localStorage.getItem('tippro'))
          self.closeFSL()
        })
        .catch(function (error) {
          console.log('Catch getTippro')
          console.log(error)
          self.closeFSL()
        })
    },
    getFstCot: function (e) {
      console.log('proximo COT ID')
      var self = this
      const url = 'http://' + this.getArea.ip + '/sc/getFstCot/'
      axios.interceptors.response.use(function (response) {
        return response
      }, function (error) {
        if (error.response.status === 401) {
          router.push('/login')
        }
        return Promise.reject(error)
      })
      axios.get(url, {
        dataType: 'json',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: self.getToken.token
        },
        mode: 'no-cors',
        credentials: 'include'
      })
        .then(function (response) {
          localStorage.setItem('FstCot', JSON.stringify(response.data).split('T00:00:00Z[-03:00]').join(''))
          self.FstCot = JSON.parse(localStorage.getItem('FstCot'))
          console.log('cotizacion con el menor indice')
          console.log(self.FstCot[0].ID)
          self.COT_Fst_ID = self.FstCot[0].ID
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    getPendienteXusr: function (e) {
      var self = this
      self.openFSL()
      this.s_artcod = []
      const url = 'http://' + this.getArea.ip + '/sc/getPendiente/params;usrid=' + this.getToken.user + ';byusr=N'

      axios.interceptors.response.use(function (response) {
        return response
      }, function (error) {
        if (error.response.status === 401) {
          router.push('/login')
        }
        return Promise.reject(error)
      })
      axios.get(url, {
        dataType: 'json',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: self.getToken.token
        },
        mode: 'no-cors',
        credentials: 'include'
      })
        .then(function (response) {
          localStorage.setItem('PendienteXusr', JSON.stringify(response.data).split('T00:00:00Z[-03:00]').join(''))
          self.PendienteXusr = JSON.parse(localStorage.getItem('PendienteXusr'))
          self.closeFSL()
        })
        .catch(function (error) {
          console.log(error)
          self.closeFSL()
        })
    },
    getProxCot_Id: function (e) {
      console.log('proximo COT ID')
      var self = this
      const url = 'http://' + this.getArea.ip + '/sc/getProxCotId/'
      axios.interceptors.response.use(function (response) {
        return response
      }, function (error) {
        if (error.response.status === 401) {
          router.push('/login')
        }
        return Promise.reject(error)
      })
      axios.get(url, {
        dataType: 'json',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: self.getToken.token
        },
        mode: 'no-cors',
        credentials: 'include'
      })
        .then(function (response) {
          localStorage.setItem('NroSolicitud', JSON.stringify(response.data).split('T00:00:00Z[-03:00]').join(''))
          self.NroCotizacion = JSON.parse(localStorage.getItem('NroSolicitud'))
          self.COT_ID = self.NroCotizacion[0].ID
          console.log('COT ID:' + self.COT_ID)
          self.consolidar()
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    consolidar () {
      // hacer un for que recorra la presolicitud y meta todo a Inser_COT
      this.Precot.forEach(solid => this.functionTest(solid))
      this.successMsg('se registró la Cotizacion NRO:' + this.COT_ID)
      // borrar el listado de presolicitud
      this.vaciarPreCotizacion()
      this.getPendienteXusr()
    },
    functionTest (e) {
      this.INS_SC_ID = e.ID_SO
      this.INS_SC_ITM = e.ID_ITM
      this.Inser_COT()
    },
    Inser_COT () {
      var self = this
      self.openFSL()
      // http://34.69.86.51:8080/sc/INS_COT/params;cot_id=29;sc_id=8;sc_itm=7;
      fetch('http://' + this.getArea.ip + '/sc/INS_COT/params;cot_id=' + self.COT_ID + ';sc_id=' + self.INS_SC_ID + ';sc_itm=' + self.INS_SC_ITM, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: self.getToken.token
        },
        body: JSON.stringify({
        })
      })
        .then(async response => {
          this.EstadoR = await response.json()
          self.closeFSL()
        })
        .catch(error => {
          console.error('Mensaje de error: ', error)
          this.errorMsg(error)
          self.closeFSL()
        })
    }

  }
}
</script>
<style>
  tr:hover {
    background-color: rgb(6, 10, 39);
  }
</style>
