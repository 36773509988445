<template>
  <div>
    <p style="color:red" v-if="online">⚠️ El servicio no está en línea. notificar a sistemas4@penna.com.ar asunto: "Solicitud de compras fuera de linea" ⚠️</p>
  </div>
  <h1 v-if="!ConfIp">Obteniendo IP...</h1>
  <div v-if="ConfIp">
    <div class='row'>
      <div class='col'>
        <el-menu
        :default-active="activeIndex"
        class="el-menu-demo"
        mode="horizontal"
        @select="handleSelect"
        v-show="getArea.area != null">
          <el-menu-item index="1" @click="xurl('/')">Solicitud</el-menu-item>
          <el-menu-item index="2" @click="xurl('/screport')">Reporte</el-menu-item>
          <el-menu-item index="3" @click="xurl('/cotizacion')" v-if="this.getArea.area==='it' || this.getArea.area==='COM'" >Agrupador solicitudes para Cotizar</el-menu-item>
          <el-menu-item index="4" @click="xurl('/cotinfo')" v-if="this.getArea.area==='it' || this.getArea.area==='COM'" >Cotizaciones abiertas</el-menu-item>
          <el-menu-item index="5" @click="salir" style="color:coral">Cerrar Sesion de {{this.getArea.user}}</el-menu-item>
          <el-menu-item index="6" onclick="location.href='https://grupopenna.com.ar/issues/'" style="color:yellowgreen">Notificar incidencia</el-menu-item>
          <!--- <el-menu-item index="7" >{{ this.getArea.ip }}</el-menu-item> --->
        </el-menu>
      </div>
    </div>
  <br>
    <router-view/>
    <!--- <div>
    <h1>Mensajes de la consola:</h1>
      <ul>
        <li v-for="log in consoleLogs" :key="log">{{ log }}</li>
      </ul>
    </div> --->
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import shared from './shared/shared'
import { ref } from 'vue'
import config from './components/config.js'
import axios from 'axios'

// var description = 'SC_V2'
export default {
  extends: shared,
  setup () {
    const activeIndex = ref('1')
    const handleSelect = (key, keyPath) => {
      // console.log(key, keyPath)
    }
    return {
      activeIndex,
      online: false,
      handleSelect
    }
  },
  async created () {
    const respondsse = await config.getConfig()
    if (respondsse) {
      await new Promise((resolve, reject) => {
      // Envuelve la llamada a setIp en una promesa
        Promise.resolve(this.setIp(respondsse)).then(() => {
          resolve()
          this.ConfIp = true
          this.checkOnline()
        }).catch((error) => {
          reject(error)
        })
      })
    } else {
    // Manejo del error: config.getConfig() devolvió undefined
    }
  },
  data () {
    return {
      pageTitle: 'Título por defecto',
      ConfIp: false
    }
  },
  computed: {
    ...mapGetters(['getIp']),
    ...mapGetters(['getArea']),
    consoleLogs () {
      return console.getLogs()
    }
  },
  methods: {
    xurl (param) {
      this.$router.push(param)
    },
    ...mapMutations(['setUser', 'setToken', 'setArea', 'setIp']),
    salir () {
      this.setUser(null)
      this.setArea(null)
      this.setToken(null)
      this.successMsg('Sesion cerrada, gracias por utilizar el portal de solicitud de compras.')
      this.$router.push('/login')
    },
    checkOnline () {
      console.log('checkonline..')
      return new Promise((resolve, reject) => {
        const ip = this.getIp.ip // Aquí utilizamos directamente el getter
        console.log('IP: ' + ip + ' is online')
        axios.get('http://' + ip + '/sc/status/')
          .then(response => {
            if (response.status === 201) {
              console.log('está Online!')
            }
            resolve(response) // Resolvemos la promesa con la respuesta
          })
          .catch(error => {
            console.log(error)
            this.online = true
            reject(error) // Rechazamos la promesa con el error
          })
      })
    }
  },
  mounted () {
    /*
    var consoleLogs = []
    function captureConsoleLog (...args) {
      consoleLogs.push(args.map(arg => arg.toString()).join(' '))
      // También puedes enviar el mensaje a un servidor o almacenarlo en otro formato aquí
    }

    var consoleMethods = ['log', 'error', 'warn', 'info', 'debug', 'assert']

    consoleMethods.forEach(function (method) {
      var originalMethod = console[method]
      console[method] = function (...args) {
        captureConsoleLog(...args)
        originalMethod.apply(console, args)
      }
    })

    // Acceder al historial de mensajes de la consola
    console.getLogs = function () {
      return consoleLogs
    } */
  }
}
</script>

<style>
.red-text {
  color: red;
}

.yellow-text {
  color: yellow;
}

.green-text {
  color: green;
}

.cyan-text {
  color: cyan;
}

/*html {
  height: 100%;
}*/
html,body {
  height: 100%;
  margin:0;
  padding:0;
  font-family: sans-serif;
  background: linear-gradient(#1e2428, #1d2429);
  /* #262628 usar este color de fondo*/
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center;*/
  background-color: #1e2428;
  color: #bbbfc0;
}
#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
th {
  background: #24567a;
  position: sticky;
  top: 0;
  box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.4);
  color: rgb(212, 208, 208);
}
td {
  color: rgb(212, 208, 208);
}
td.hover {
  color: rgb(212, 208, 208);
}
tr.blue th {
  background: #008AFF;
  color: rgb(212, 208, 208);
}
tr.gray td {
  background: gray;
  position: sticky;
  color: rgb(212, 208, 208);
}
.shadow {
  box-shadow: 0px 0px 31px -1px #000;
}
.box-card {
  background: #222933;
}
.el-input--suffix .el-input__inner {
    margin-bottom: 15px;
}
.el-button--primary {
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 20px;
  color: white;
  padding: 10px 20px;
  font-size: 14px;
  border: none;
}
a {
    color: white;
    text-decoration: underline;
}

table:hover td {
  color: white;
}

</style>
