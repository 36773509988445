import { createRouter, createWebHistory } from '@ionic/vue-router'
import Login from '../views/Login.vue'
import SC from '../views/SC.vue'
import SCcot from '../views/SC_cot.vue'
import SCcotInfo from '../views/SC_cot_info.vue'
import SCcotroute from '../views/SC_cot_info_route.vue'
import SCcotProv from '../views/SC_cot_prov.vue'
import test from '../views/layout.vue'
import screport from '../views/SC_Report.vue'
import licitacion from '../views/SC_lic.vue'
import Test2 from '../views/test_F.vue'
/* import adj from '../views/SC_adj.vue'
import adj2 from '../views/SC_adj2.vue' */

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/',
    name: 'SC',
    component: SC
  },
  {
    path: '/cotizacion',
    name: 'SC_cot',
    component: SCcot
  },
  {
    path: '/cotinfo/:id',
    name: 'SC_cot_info',
    component: SCcotInfo
  },
  {
    path: '/cotinfo',
    name: 'SCcotroute',
    component: SCcotroute
  },
  {
    path: '/cotbyprov/:id',
    name: 'SC_cot_prov',
    component: SCcotProv
  },
  {
    path: '/test',
    name: 'test',
    component: test
  },
  {
    path: '/screport',
    name: 'screport',
    component: screport
  },
  {
    path: '/licitacion/:id',
    name: 'licitacion',
    component: licitacion
  },
  {
    path: '/Test2/',
    name: 'Test2',
    component: Test2
  }
  /*,
  {
    path: '/adj',
    name: 'adj',
    component: adj
  },
  {
    path: '/adj2',
    name: 'adj2',
    component: adj2
  } */
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
