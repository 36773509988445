<template>
  <!-- El resto de tu código de plantilla aquí -->
  <a-component ref="aComponent" :TSTrecive="TSTdb" @tst-send="cargaTSTenDB"></a-component>
  <button @click="enviarTSTaADJ">Enviar TST a ADJ</button>
  <button @click="cargaTSTenDB">cargar de ADJ</button>
  <input type="number" name="" id="" v-bind="TSTdb">
</template>

<script>
import AComponent from '@/components/SC_adj.vue'

export default {
  components: {
    AComponent
  },
  data () {
    return {
      TSTdb: ''
    }
  },
  methods: {
    enviarTSTaADJ () {
      // Asumiendo que aquí obtienes el valor para TSTdb desde alguna fuente, como una API
      // this.TSTdb = '479537814'
      // this.$refs.aComponent.TSTrecive = this.TSTdb
      this.AComponent.getAdjuntos(this.TSTdb)
    },
    cargaTSTenDB (TSTSend) {
      this.TSTdb = AComponent.getters.tstadj
    }
  }
}
</script>
