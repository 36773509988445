import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap'
import ElementPlus from 'element-plus'
import Unicon from 'vue-unicons'
// import { uniPaperclip } from 'vue-unicons/dist/icons'
import 'element-plus/dist/index.css'
import JsonExcel from 'vue-json-excel'
import 'element-theme-dark'
// import VueAnalytics from 'vue-analytics'
// import VueApexCharts from 'vue3-apexcharts'

// Unicon.add([uniPaperclip])
const app = createApp(App)
app.use(Unicon)
app.use(store)
app.use(router)
app.use(JsonExcel)
app.use(ElementPlus)
/* app.use(VueAnalytics, {
  id: 'G-F1976JMN44' // reemplazar con su ID de seguimiento de Google Analytics
}) */
app.mount('#app')
