export default {
  data: function () {
    return {
      impIndex: 0
    }
  },
  methods: {
    isNumber (evt) {
      evt = evt || window.event
      var charCode = (evt.which) ? evt.which : evt.keyCode
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    openFSL (self) {
      self.loading = self.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
    },
    closeFSL (self) {
      self.loading.close()
    },
    bar () {
      alert('bar')
    },
    successMsg (val) {
      this.$message({
        message: val,
        type: 'success'
      })
    },
    errorMsg (val) {
      this.$message({
        message: 'mensaje de error: ' + val,
        type: 'error'
      })
    },
    numberFormat (val, digit) {
      const formatter = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: digit,
        maximumFractionDigits: digit
      })
      return formatter.format(val)
    }
  }
}
