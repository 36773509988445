<template>
  <h5> TSTs en componente ADJ: {{ TSTs }}</h5>
    <!------------------------------------------------------- Modulo adjuntador de archivos ---------------------------------------------------->
    <el-card  class="box-card shadow">
        <div>
        <h3 v-if="this.items.length > 0">Carga de Archivos</h3>
      <div v-for="(item, index) in items" :key="index" class="form-group row">
        <div class="col-sm-1">
          <button class="btn btn-danger" @click="removeItem(index)"><i class="el-icon-delete"></i></button>
        </div>
        <div class="col-sm-2">
          <input type="text" class="form-control" v-model="item.OBS" placeholder="Observación">
        </div>
        <div class="col-sm-2">
                <input class="btn btn-warning" type="file" @change="handleFileUpload($event, item)">
        </div>
      </div>
      <button class="btn btn-primary" @click="addItem">Nuevo Adjunto  <i class="el-icon-plus"></i></button>
      <button class="btn btn-primary" @click="this.AdjVisible = true">Traer de repositorio  <i class="el-icon-plus"></i></button>
    </div>
    <div>
        <img v-if="isLoading" src="/svg/uploadAnimado.gif" alt="Cargando..." />
        <div style="white-space: nowrap; overflow-x: auto;">
            <div v-for="(item, index) in items" :key="index" style="display: inline-block;" class="image-container">
                <img :src="item.miniatura" />
            </div>
        </div>
  </div>
      <button class="btn btn-success" @click="consolidar" icon="el-icon-upload" v-if="this.items.length > 0"> <i class="el-icon-upload"></i> Subir todos los archivos</button>
</el-card>
<!------------------------------------------------------- Modulo adjuntador de archivos ---------------------------------------------------->

<!-------------------------------------------&& VerGalery === false"------------ Galeria de miniaturas Base64 ---------------------------------------------------->
<button @click="VerGalery=true">verGaleri</button>
<el-card class="box-card shadow" v-if="adjuntos.length > 0" >
  <div>
    <div class="gallery-container">
        <div v-for="(adj, index) in adjuntos" :key="index" class="image-container">
            <img :src="adj.MINB64" @click="VerImg(adj.MINB64)" v-if="adj.EXT && adj.EXT.match(/(jpg|jpeg|png|gif|bmp|pdf)$/i)" />
            <img :src="adj.MINB64" @click="downloadFile(adj.TSTORI, adj.NOMBRE)" v-else-if="adj.EXT && !adj.EXT.match(/(jpg|jpeg|png|gif|bmp|pdf)$/i)" />
            <el-button  @click="downloadFile(adj.TSTORI, adj.NOMBRE)" v-if="adj.EXT && adj.EXT.match(/(jpg|jpeg|png|gif|bmp|pdf)$/i)">Descargar</el-button>
            <el-button  @click="deleteFile(adj.TSTORI)" v-if="adj.EXT && adj.EXT.match(/(jpg|jpeg|png|gif|bmp|pdf)$/i)">Eliminar</el-button>
        </div>
        <el-card class="box-card shadow" v-if="isdownloading" >
          <h5  style="color:yellow;">
            Preparando descarga
          <span class="dot">.</span><span class="dot">.</span><span class="dot">.</span>
          </h5>
        </el-card>
    </div>
  </div>
</el-card>

<!------------------------------------------------------- GALERIA ---------------------------------------------------->

<!------------------------------------------------------- Visor emergente IMG / PDF ---------------------------------------------------->
<el-dialog
    v-model="this.imgVisible"
    title="IMAGEN"
    width="40%"
  >
    <div class="row">
      <vue-pdf-embed :source="imgData" name="plugin2" v-if="imgData.includes('pdf;')"/>
      <embed width="100%" height="100%" name="plugin2" :src="imgData" type="image/png" v-if="!imgData.includes('pdf;')">
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="this.imgVisible = false" class="btn btn-primary">Cerrar</el-button>
      </span>
    </template>
  </el-dialog>
  <!------------------------------------------------------- Visor emergente IMG / PDF ---------------------------------------------------->

  <!------------------------------------------------------- busqueda listado de adjuntos ---------------------------------------------------->
<el-dialog
    v-model="this.AdjVisible"
    title="Repositorio de Archivos"
    width="30%"
  >
    <div class="row">
      <div class="col-md-3">
      <el-input type='text' v-model="fnd_nombre" placeholder= 'Nombre' @change="articuloOR()" />
      </div>
      <div class="col-md-3">
      <el-input type='text' v-model="fnd_observ" placeholder= 'Observacion' @change="articuloOR()" />
      </div>
      <div class="col-md-3">
      <el-input type='text' v-model="fnd_userid" placeholder= 'Usuario' @change="articuloOR()" />
      </div>
      <div class="col-md-3">
      <el-input type='text' v-model="fnd_ext" placeholder= 'Extension de archivo' @change="articuloOR()" />
      </div>
    <br>
      <el-button type="primary" plain @click="getAdjFind()">Reporte Socicutdes <h5><i class="el-icon-search"></i></h5></el-button>
    </div>
    <table>
      <thead>
        <tr class="green">
          <th>Nombre</th>
          <th>Observacion</th>
          <th>Fecha</th>
          <th>Usuario</th>
          <th>Extension</th>
          <th>Ver Adjunto</th>
          <th>Incorporar</th>
        </tr>
      </thead>
      <tbody>
            <tr v-for='(rep, i) in repositorio' :key='i'>
              <td v-html="rep.nombre"></td>
              <td v-html="rep.observ"></td>
              <td v-html="rep.fecha"></td>
              <td v-html="rep.userid"></td>
              <td v-html="rep.ext"></td>
              <td >ver</td>
              <td ><el-button @click="addTST(rep.tst)" class="btn btn-primary">Agregar</el-button></td>
            </tr>
          </tbody>
    </table>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="this.AdjVisible = false" class="btn btn-primary">Cerrar</el-button>
      </span>
    </template>
  </el-dialog>
  <!------------------------------------------------------- busqueda listado de adjuntos ---------------------------------------------------->
//////////////////////////////////////////// resolver la concurrencia sobre TST que se repite y carga info en otros modulos
  <!------------------------------------------------------- listado de adjuntos [GALERIA] ---------------------------------------------------->
  <el-dialog
    v-model="this.GaleryVisible"
    title="Repositorio de Archivos"
  >
    <div v-if="this.cargandolistado">
    <h5 style="color:yellow;">Cargando Listado<span class="dot">.</span><span class="dot">.</span><span class="dot">.</span>
    </h5>
    </div>
    <el-table :data="adjuntos" style="width: 100% ">
      <el-table-column prop="NOMBRE" label="Nombre" />
      <el-table-column prop="OBS" label="Observacion" />
      <el-table-column prop="FECHA" label="Fecha" />
      <el-table-column prop="USERID" label="Usuario" />
      <el-table-column prop="EXT" label="Extension" />
      <el-table-column fixed="right" label="VER" width="120">
        <template  #default="scope">
          <el-button link type="primary" size="small" @click="VerImg(scope.row.TSTORI)">
            ver
          </el-button>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="Descargar" width="120">
        <template  #default="scope">
          <el-button link type="primary" size="small" @click="downloadFile(scope.row.TSTORI,scope.row.NOMBRE)">
            Descargar
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <template #footer>
      <el-card class="box-card shadow" v-if="isLoading" >
          <h5  style="color:yellow;">
            Preparando Imagen
          <span class="dot">.</span><span class="dot">.</span><span class="dot">.</span>
          </h5>
        </el-card>
      <span class="dialog-footer">
        <el-button @click="this.GaleryVisible = false" class="btn btn-success">Cerrar</el-button>
      </span>
    </template>
  </el-dialog>
  <!------------------------------------------------------- listado de adjuntos [GALERIA] ---------------------------------------------------->
  </template>

<script>
// import axios from 'axios'
import { mapGetters } from 'vuex'
import { ref } from 'vue'
import VuePdfEmbed from 'vue-pdf-embed'
import axios from 'axios'
import config from '../../public/config.json'
export default {
  props: {
    TSTrecive: String
  },
  components: {
    VuePdfEmbed
  },
  data () {
    return {
      B64fullData: '',
      isdownloading: false,
      imgVisible: ref(false),
      AdjVisible: ref(false),
      GaleryVisible: ref(false),
      cargandolistado: false,
      imgData: '',
      AdjData: '',
      ip: '34.67.86.78:8080',
      isLoading: false,
      B64full: null, // [{ B64FULL: null }],
      items: [
        {
          OBS: '',
          id: '',
          valor: '',
          nombreArchivo: '',
          archivo: null,
          miniatura: null,
          extension: null
        }
      ],
      adjuntos: [
        {
          OBS: '',
          DIR: '',
          valor: '',
          NOMBRE: '',
          MINB64: null,
          EXT: null,
          TSTORI: null,
          FECHA: '',
          USERID: ''
        }],
      ins_OBS: '',
      ins_id: '',
      ins_valor: '',
      ins_nombreArchivo: '',
      ins_archivo: null,
      ins_mini: null,
      ins_ext: null,
      TSTimg: [],
      TSTs: null,
      TSTsend: null,
      fnd_nombre: '',
      fnd_observ: '',
      fnd_userid: '',
      fnd_ext: '',
      repositorio: [{
        tst: '',
        nombre: '',
        observ: '',
        fecha: '',
        userid: '',
        ext: ''
      }]
    }
  },
  computed: {
    ...mapGetters(['getToken'])
  },
  beforeMount: function () {
    this.adjuntos.length = 0
    this.items.length = 0
    this.repositorio.length = 0
    // this.getAdjuntos()
  },
  methods: {
    openFSL (self) {
      self.loading = self.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
    },
    closeFSL (self) {
      self.loading.close()
    },
    VerImg (tst) {
      var ver = true
      this.getB64FULL(tst, ver, 'saraza')
      this.isLoading = true
    },
    VerAdj (adj) {
      this.AdjData = adj
      this.AdjVisible = true
    },
    VerGalery (adj) {
      this.AdjData = adj
      this.GaleryVisible = true
    },
    addItem () {
      this.items.push({
        OBS: '',
        id: '',
        valor: '',
        nombreArchivo: '',
        archivo: null,
        miniatura: null
      })
    },
    removeItem (index) {
      this.items.splice(index, 1)
    },
    upload (e) {
      document.getElementById('uploadFile' + e.index).click()
    },
    enviarTSTalPadre () {
      console.log('enviarTSTalPadre: ' + this.TSTs)
      this.$emit('enviar-tst', this.TSTs)
    },
    recibirTSTdelPadre (TSTs) {
      this.TSTs = TSTs
      console.log('recibirTSTdelPadre' + this.TSTs + 'param: ' + TSTs)
      this.getAdjuntos()
    },
    TstParaGaleria (TSTs) {
      this.TSTs = TSTs
      console.log('Componente TstParaGaleria' + this.TSTs + 'param: ' + TSTs)
      this.getAdjuntos()
      this.GaleryVisible = true
      this.cargandolistado = true
    },
    downloadFile (tst, nombre) {
      const ver = false
      console.log(tst)
      this.getB64FULL(tst, ver, nombre)
      // const base64Data = 'data:image/png;base64,iVBORw0KG...' // Reemplaza esto con el valor almacenado en tu variable
      // const fileName = 'archivo.png' // Reemplaza esto con el nombre de archivo deseado
      // this.downloadBase64File(this.B64full, 'archivo.xlsx')
    },
    /* downloadBase64File (base64Data, fileName) {
      // Crear un enlace temporal
      const link = document.createElement('a')
      link.href = base64Data
      link.download = fileName
      // Adjuntar el enlace al DOM, simular un clic en él y luego eliminarlo
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }, */
    deleteFile (tst) {
    // Divide this.TSTs en un array utilizando el método split(',')
      const TSTsArray = this.TSTs.split(',')
      console.log('valor parametro tst: ' + tst)
      console.log(TSTsArray)
      console.log('filtrado: ' + TSTsArray.filter((value) => value.toString() !== tst.toString()))
      // Filtrar el array para eliminar el valor que coincide con tst
      const filteredTSTsArray = TSTsArray.filter((value) => value.toString() !== tst.toString())

      // Unir el array filtrado en una cadena utilizando el método join(',')
      this.TSTs = filteredTSTsArray.join(',')
      console.log('tst luego de elimiar: ' + this.TSTs)
      // Filtrar el array adjuntos para eliminar el elemento con TSTORI que coincide con tst
      this.adjuntos = this.adjuntos.filter((item) => item.TSTORI !== tst)
      this.enviarTSTalPadre()
    },

    handleFileUpload (event, item) {
      console.log(item)
      const file = event.target.files[0]
      item.archivo = null // se borra el valor anterior del archivo
      item.nombreArchivo = file.name
      item.id = file.name
      const reader = new FileReader()
      reader.onloadend = async () => {
        item.archivo = reader.result

        // Verificamos si el archivo es una imagen
        const extension = item.nombreArchivo.split('.').pop().toLowerCase()
        const esImagen = ['jpg', 'jpeg', 'png', 'gif', 'bmp'].includes(extension)
        const esPDF = extension === 'pdf'
        item.extension = extension
        if (esImagen) {
          // Si es una imagen, creamos la miniatura como en la versión 2
          const imagenOriginal = new Image()
          imagenOriginal.onload = () => {
            const anchoOriginal = imagenOriginal.width
            const altoOriginal = imagenOriginal.height

            // Creamos un canvas para dibujar la miniatura
            const canvas = document.createElement('canvas')
            const ctx = canvas.getContext('2d')

            // Definimos las dimensiones de la miniatura
            const escala = anchoOriginal / 200
            const anchoMiniatura = 200
            const altoMiniatura = altoOriginal / escala

            // Dibujamos la imagen original en el canvas con las dimensiones de la miniatura
            ctx.drawImage(imagenOriginal, 0, 0, anchoMiniatura, altoMiniatura)

            // Obtenemos la miniatura como una cadena base64
            item.miniatura = canvas.toDataURL()
            // console.log(canvas.toDataURL())

            this.mostrarArchivosRepetidos()
          }
          imagenOriginal.src = item.archivo
        } else if (esPDF) {
          // Si es un PDF, creamos una miniatura con el SVG
          const canvas = document.createElement('canvas')
          const ctx = canvas.getContext('2d')
          canvas.width = 200
          canvas.height = 200

          const pdfSvgUrl = '/svg/PDF.svg'
          const pdfSvgResponse = await fetch(pdfSvgUrl)
          const pdfSvgText = await pdfSvgResponse.text()
          // https://icon-icons.com/es/download/176234/SVG/512/
          const svgBlob = new Blob([pdfSvgText], { type: 'image/svg+xml;charset=utf-8' })
          const svgUrl = URL.createObjectURL(svgBlob)

          const pdfSvgImage = new Image()
          pdfSvgImage.onload = () => {
            ctx.drawImage(pdfSvgImage, 0, 0, canvas.width, canvas.height)

            // Agregamos el nombre del archivo debajo del SVG
            ctx.font = 'bold 15px Arial'
            ctx.textAlign = 'center'
            ctx.textBaseline = 'middle'
            const nombreArchivo = item.nombreArchivo
            const textWidth = ctx.measureText(nombreArchivo).width
            const padding = 5

            // Dibujamos un rectángulo de fondo detrás del texto
            ctx.fillStyle = '#f44336'
            ctx.fillRect(
              canvas.width / 2 - textWidth / 2 - padding,
              canvas.height - 20 - ctx.font.split('px')[0] / 2 - padding,
              textWidth + padding * 2,
              parseInt(ctx.font) + padding * 2
            )

            // Dibujamos el texto encima del rectángulo
            ctx.fillStyle = '#fff'
            ctx.fillText(nombreArchivo, canvas.width / 2, canvas.height - 20)

            // Obtenemos la miniatura como una cadena base64
            item.miniatura = canvas.toDataURL()
            this.mostrarArchivosRepetidos()
          }

          pdfSvgImage.src = svgUrl
        } else {
          // Si no es una imagen, creamos una miniatura con la sugerencia como en la versión 1
          const canvas = document.createElement('canvas')
          const ctx = canvas.getContext('2d')
          canvas.width = 200
          canvas.height = 200
          ctx.fillStyle = '#f0f0f0'
          ctx.fillRect(0, 0, canvas.width, canvas.height)
          ctx.font = 'bold 16px Arial'
          ctx.fillStyle = '#888888'
          ctx.textAlign = 'center'
          ctx.textBaseline = 'middle'
          const extensionMinuscula = extension.toLowerCase()
          const nombreMinuscula = item.nombreArchivo.toLowerCase()
          ctx.fillText(`.${extensionMinuscula}`, canvas.width / 2, canvas.height / 2 - 10)
          ctx.font = 'bold 12px Arial'
          ctx.fillText(nombreMinuscula.substring(0, nombreMinuscula.length - extension.length - 1), canvas.width / 2, canvas.height / 2 + 10)

          // Obtenemos la miniatura como una cadena base64
          item.miniatura = canvas.toDataURL()

          this.mostrarArchivosRepetidos()
        }
      }
      reader.readAsDataURL(file)
    },
    mostrarArchivosRepetidos () {
      const nombresArchivos = this.items.map((item) => item.nombreArchivo)
      const nombresArchivosRepetidos = nombresArchivos.filter((nombreArchivo, index, array) => array.indexOf(nombreArchivo) !== index)
      if (nombresArchivosRepetidos.length === 1) {
        console.log('Archivo repetido:', nombresArchivosRepetidos[0])
        alert('Archivo repetido: ' + encodeURIComponent(nombresArchivosRepetidos[0]))
      }
    },
    async consolidar () {
      // this.shared.openFSL()
      this.isLoading = true
      console.log('inicia InsertaUnoaUno')
      const promises = this.items.map(solid => this.InsertaUnoaUno(solid))
      await Promise.all(promises)
      console.log('termina InsertaUnoaUno')
      this.isLoading = false
      console.log(this.items.length)
      this.items.length = 0
      console.log(this.items.length)
      console.log('inicia join')
      this.joinTST(this.TSTimg).then(() => {
        console.log('termina join e inicia getAdjuntos')
        console.log(this.TSTs)
        this.getAdjuntos()
        console.log('termina Adjuntos')
        // self.closeFSL()
      })
      this.enviarTSTalPadre()
    },
    addTST (tst) {
      console.log('addTST: ' + this.TSTs)
      if (this.TSTs === null) {
        console.log('addTST this.TSTs esta vacio se asigna tst')
        this.TSTs = tst
      } else {
        console.log('addTST no esta vacio')
        if (typeof this.TSTs === 'string') {
          const tstsArray = this.TSTs.split(',')
          if (tstsArray.includes(tst)) {
            console.log('El TST ya existe')
          } else {
            console.log('El TST NO existe')
            this.TSTs += ',' + tst
            this.getAdjuntos()
            console.log(this.TSTs)
            this.enviar()
          }
        } else {
          console.log('tst no es string')
          this.joinTST(tst)
        }
      }
      console.log('FIN addTST: ' + this.TSTs)
      this.joinTST(tst)
    },
    joinTST (tst) {
      return new Promise((resolve) => {
        console.log('TSTimg antes de join')
        console.log(tst)
        console.log(this.TSTs)
        const TSTimgArray = Array.from(tst)
        console.log(TSTimgArray)
        console.log(this.TSTs)

        // Si TSTs ya tiene valores, dividirlos por coma y almacenarlos en un array
        const existingTSTs = this.TSTs && typeof this.TSTs === 'string' ? this.TSTs.split(',') : []

        // Crear un nuevo array combinando los valores existentes en TSTs y los nuevos valores de TSTimgArray
        const combinedTSTs = [
          ...existingTSTs,
          ...TSTimgArray.map((obj) => obj.ID)
        ]

        // Eliminar duplicados y unir los valores con comas
        this.TSTs = [...new Set(combinedTSTs)].join(',')
        if (this.TSTs.startsWith(',')) {
          this.TSTs = this.TSTs.slice(1)
        }
        console.log(this.TSTs)
        resolve()
      })
    },
    /* [version 20/04/2023]joinTST (tst) {
      return new Promise((resolve) => {
        console.log('TSTimg antes de join')
        console.log(tst)
        console.log(this.TSTs)
        const TSTimgArray = Array.from(tst)
        // O también puedes usar el operador de propagación: const TSTimgArray = [...this.TSTimg]
        console.log(TSTimgArray)
        console.log(this.TSTs)
        // this.TSTs = TSTimgArray.map(obj => obj.ID).join(',')

        // Si TSTs ya tiene valores, dividirlos por coma y almacenarlos en un array
        const existingTSTs = this.TSTs ? this.TSTs.split(',') : []

        // Crear un nuevo array combinando los valores existentes en TSTs y los nuevos valores de TSTimgArray
        const combinedTSTs = [
          ...existingTSTs,
          ...TSTimgArray.map((obj) => obj.ID)
        ]

        // Eliminar duplicados y unir los valores con comas
        this.TSTs = [...new Set(combinedTSTs)].join(',')

        console.log(this.TSTs)
        resolve()
      })
    }, */
    enviar () {
      this.TSTsend = this.TSTs
      this.$emit('tst-send', this.TSTsend)
    },
    cargarIMG () {
      this.TSTs = this.TSTrecive
    },
    /* joinTST () {
      return new Promise((resolve) => {
        console.log('this.TSTimg:')
        console.log(this.TSTimg)
        const tstadjArray = Array.from(this.TSTimg)
        // O también puedes usar el operador de propagación: const tstadjArray = [...this.tstadj];
        console.log('tstadjArray:')
        console.log(tstadjArray)
        this.tsts = tstadjArray.map(obj => obj.ID).join(',')
        console.log('this.tsts:')
        console.log(this.tsts)
        resolve()
      })
    }, */
    async InsertaUnoaUno (e) {
      console.log('inicia 1 a 1')
      this.ins_OBS = e.OBS
      this.ins_id = e.id
      this.ins_valor = e.valor
      this.ins_nombreArchivo = e.nombreArchivo
      this.ins_archivo = e.archivo
      this.ins_mini = e.miniatura
      this.ins_ext = e.extension

      // Agrega 'await' antes de 'this.Insert_adj()'
      await this.Insert_adj()
    },

    async Insert_adj () {
      console.log('inicia Insert_adj')
      var self = this
      // self.openFSL()
      // http://34.67.86.78:8080/sc/ins_adj/params;categoria=ga;nombre=se;directorio=lala;valor=32
      console.log(
        'http://' +
      config.ip +
      '/sc/ins_adj/params;categoria=' +
      encodeURIComponent(this.ins_OBS) +
      ';nombre=' +
      encodeURIComponent(this.ins_id) +
      ';directorio=;valor=;ext=' +
      this.ins_ext
      )

      // Devuelve la promesa de 'fetch'
      return fetch(
        'http://' +
      config.ip +
      '/sc/ins_adj/params;categoria=' +
      encodeURIComponent(this.ins_OBS) +
      ';nombre=' +
      encodeURIComponent(this.ins_id) +
      ';directorio=;valor=;ext=' +
      this.ins_ext,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: self.getToken.token
          },
          body: JSON.stringify({
            imagen: this.ins_archivo,
            miniatura: this.ins_mini
          })
        }
      )
        .then(async (response) => {
          var TSTid = await response.json()
          // this.TSTimg = TSTid.ID
          // console.log(TSTid[0].ID)
          this.TSTimg.push({ ID: TSTid[0].ID })
        })
        .catch((error) => {
          console.error('Mensaje de error: ', error)
        })
    },
    getAdjuntos: function () {
      var self = this
      // self.openFSL()
      const url = 'http://' + config.ip + '/sc/getAdjByTst/params;tstfiles=' + self.TSTs
      console.log(url)
      console.log('this.TSTs en getAdjuntos: ' + self.TSTs)
      axios.interceptors.response.use(function (response) {
        return response
      }, function (error) {
        return Promise.reject(error)
      })
      axios.get(url, {
        dataType: 'json',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: self.getToken.token
        },
        mode: 'no-cors',
        credentials: 'include'
      })
        .then(function (response) {
          localStorage.setItem('Adjuntos', JSON.stringify(response.data).split('T00:00:00Z[-03:00]').join(''))
          self.adjuntos = JSON.parse(localStorage.getItem('Adjuntos'))
          console.log('Cantidad de adjuntos: ' + self.adjuntos.length)
          self.cargandolistado = false
          // self.closeFSL()
        })
        .catch(function (error) {
          console.log('Error Get Adjuntos')
          console.log(error)
          // self.closeFSL()
        })
    },
    getB64FULL: function (tst, ver, nombre) {
      this.isdownloading = true
      var self = this
      const url = 'http://' + config.ip + '/sc/getB64FULL/params;tstfiles=' + tst
      console.log(url)

      axios.get(url, {
        dataType: 'json',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: self.getToken.token
        },
        mode: 'no-cors',
        credentials: 'include'
      })
        .then(function (response) {
          self.B64fullData = response.data[0].B64FULL

          if (ver) {
            console.log('ver si')
            this.imgData = this.B64fullData
            this.imgVisible = true
            this.isLoading = false
          } else {
            console.log('ver no (downloadBase64File)')
            // self.downloadBase64File(self.B64fullData, nombre)
          }
        })
        .catch(function (error) {
          console.log('Error getB64FULL')
          console.log(error)
        })
    },
    downloadBase64File: function (base64Data, filename) {
      const link = document.createElement('a')
      link.href = base64Data
      link.download = filename
      link.click()
      link.remove()
      this.isdownloading = false
    },
    getAdjFind: function () {
      var self = this
      // self.openFSL()
      const url = 'http://' + config.ip + '/sc/getAdjFind/params;nombre=' + self.fnd_nombre + ';observ=' + self.fnd_observ + ';userid=' + self.fnd_userid + ';ext=' + self.fnd_ext
      console.log(url)
      axios.interceptors.response.use(function (response) {
        return response
      }, function (error) {
        return Promise.reject(error)
      })
      axios.get(url, {
        dataType: 'json',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: self.getToken.token
        },
        mode: 'no-cors',
        credentials: 'include'
      })
        .then(function (response) {
          localStorage.setItem('Repositorio', JSON.stringify(response.data).split('T00:00:00Z[-03:00]').join(''))
          self.repositorio = JSON.parse(localStorage.getItem('Repositorio'))

          // self.closeFSL()
        })
        .catch(function (error) {
          console.log('Error getAdjFind')
          console.log(error)
          // self.closeFSL()
        })
    }
    /*
    o.put("TSTORI", objs[0]);
                o.put("CAT", objs[1]);
                o.put("NOMBRE", objs[2]);
                o.put("MINB64", objs[3]);
                o.put("DIR", objs[4]);
    */
  }
}

</script>
<style>
.image-container {
  display: inline-block;
  transition: all 0.3s ease;
  max-width: 200px;
  width: 100%;
}

.image-container:hover {
  transform: translateX(3px);
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
}
.gallery-container {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  justify-content: center;
  align-items: center;
}

@keyframes blink {
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
}

.dot {
  display: inline-block;
  animation: blink 1s linear infinite;
}

.dot:nth-child(2) {
  animation-delay: 0.33s;
}

.dot:nth-child(3) {
  animation-delay: 0.66s;
}

</style>
