<template>
  <h1> Cotización con menor nero de indice = {{this.$route.params.id}}</h1>
</template>
<script>
export default {
  mounted: function () {
    this.FstCot = JSON.parse(localStorage.getItem('FstCot'))
    this.$router.push('/cotinfo/' + this.FstCot[0].ID)
  },
  data: function () {
    return {
      FstCot: ''
    }
  }
}
</script>
