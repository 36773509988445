<template>
   <div>
     <div class="row">
       <div class="col-md-4"></div>
        <div class="col-md-3">
          <br>
          <br>
          <h1>Solicitud de compras</h1>
          <p><span>Grupo Penna</span></p>
          <form @submit.prevent='login'>
            <el-input v-model='username' placeholder='usuario' />
            <br />
            <br />
            <el-input
                v-model='password'
                placeholder='clave'
                type='password'
                show-password
            />
            <br />
            <br />
            <button type='submit' class='btn btn-primary mb-2'>Ingresar</button>
          </form>
        </div>
    </div>
  </div>
</template>
<script>
import { mapMutations, mapGetters } from 'vuex'
import shared from '../shared/shared'
import config from '../../public/config.json'
import router from '../router'
export default {
  extends: shared,
  data: () => {
    return {
      username: '',
      password: ''
    }
  },
  computed: {
    ...mapGetters(['getArea'])
  },
  methods: {
    mounted: function () {
      document.title = 'Inicio de sesion'
    },
    ...mapMutations(['setUser', 'setToken', 'setArea']),
    async login (e) {
      e.preventDefault()
      // 104.154.67.132:8081
      fetch('http://' + this.getArea.ip + '/login/auth', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          usrNombre: this.username,
          usrClave: this.password
        })
      })
        .then(async response => {
          const { user, area, JWT } = await response.json()
          this.setUser(user)
          this.setArea(area)
          this.setToken(JWT)
          if (area === null) {
            this.setArea('sin area')
            console.log('sin area')
            console.log(user)
          }
          this.successMsg('Acceso Correcto, bienvenido ' + user)
          router.push('/')
        })
        .catch(error => {
          this.setToken(null)
          console.error('Mensaje de error: ', error)
          console.log(config.ip)
          this.errorMsg(error)
        })
    }
  }
}
</script>
<style scoped>
body
{
    margin:auto;
    width:640px;
    padding:10px;
    /*background-color:#4d3737;*/
    font-size:14px;
    font-family:Verdana;
}
</style>
