<template>
  <h1>Reporte de Solicitudes de compra</h1>
<div v-if="isLoggedIn" class="container-fluid">
  <back/>
  <div class="col-md-2">
      <el-button type="warning" @click="xurl('/screport')" icon="el-icon-refresh">Recargar Página</el-button>
  </div>
  <div>

  </div>
  <el-card  class="box-card shadow">
    <div class="row">
  <h5>Filtros de búsqueda</h5>
  <div class="col-md-2">
      <el-select v-model="F_empresa"  filterable placeholder="Empresa" clearable >
                    <el-option
                      v-for="item in empresas"
                      :key="item.Base"
                      :label="item.Empresa"
                      :value="item.Base">
                    </el-option>
                  </el-select>
    </div>
    <div class="col-md-2">
      <el-select v-model="F_tippro"  @change="getArtcod()" filterable placeholder="Tipo" clearable >
                    <el-option
                      v-for="item in tipproductos"
                      :key="item.TIPPRO"
                      :label="item.DESCRP"
                      :value="item.TIPPRO"
                      >
                    </el-option>
                  </el-select>
    </div>
    <div class="col-md-2">
      <el-select v-model="F_artcod"  filterable placeholder="Artículo" clearable >
                    <el-option
                      v-for="item in Articulos"
                      :key="item.artcod"
                      :label="item.descrp"
                      :value="item.artcod">
                    </el-option>
                  </el-select>
    </div>
    <div class="col-md-2">
        <div class="form-inline">
          <el-input
            placeholder="Nro Solicitud"
            type="number"
            v-model="this.F_nroSC"
          />
        </div>
      </div>
      <div class="col-md-2">
      <el-select v-model="F_estado"  filterable placeholder="Estado" clearable >
                    <el-option
                      v-for="item in estados"
                      :key="item.item"
                      :label="item.item"
                      :value="item.item">
                    </el-option>
                  </el-select>
    </div>
</div>
<div class="row">
  <div class="col-md-10">
        <div class="form-inline">
          <el-input
            placeholder="Busqueda por descripción"
            type="text"
            v-model="this.F_descrp"
          />
        </div>
      </div>
</div>
<el-button type="primary" icon="el-icon-search" @click="getPendienteXarea()">Buscar</el-button>
<el-button type="primary" icon="el-icon-search" @click="ResetBusqueda()">Reset</el-button>
</el-card>
    <br>
<el-card  class="box-card shadow">
  <h3>Solicitudes</h3>
  <div class='row'>
    <div class="col-md-6">
        <div class="form-inline">
          <el-input
            placeholder="Filtrar tipo, artículo, empresa u observacion  🔍"
            v-model="this.search"
            @change="tablaPendiente"
          />
        </div>
      </div>
    <br>
    <table
          id="table_id"
          data-filter-control="true"
          data-search="true">
      <thead>
        <tr class="green">
          <th>Nro Sol</th>
          <th>Item</th>
          <th>NroCot</th>
          <th>Fecha</th>
          <th>Tipo</th>
          <th>Articulo</th>
          <th>Descripcion</th>
          <th>Cantidad</th>
          <th>Empresa</th>
          <th>Obser. Solicitante</th>
          <th>URL</th>
          <th>Adjunto</th>
          <th>Usuario</th>
          <th>Aarea</th>
          <th>Observ. Compras</th>
          <th></th>
          <th>Estado</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for='(imp, i) in tablaPendiente' :key='i'>
              <td v-html="imp.SC_ID"></td>
              <td v-html="imp.SC_ITM"></td>
              <td v-html="imp.COT_ID"></td>
              <td v-html="imp.FCHMOD"></td>
              <td v-html="imp.TIPPRO"></td>
              <td v-html="imp.ARTCOD"></td>
              <td v-html="imp.DESCRP"></td>
              <td v-html="imp.CANT"></td>
              <td v-html="imp.EMPRESA"></td>
              <td v-html="imp.OBSERV.slice(0, 50)"></td>
              <td ><a :href="imp.URL" target="_blank" v-truncate:[limit]="imp.URL"></a></td>
              <td ><el-button type="primary" size="mini" icon="el-icon-picture" @click="VerImg(imp.ADJ)" circle v-if="imp.ADJ.length!==0"></el-button></td>
              <td v-html="imp.USRID"></td>
              <td v-html="imp.AREA"></td>
              <td v-html="imp.ObsCot"></td>
              <td></td>
              <td>
                <span :class="{
                  'red-text': imp.ESTADO === 'Rechazado' || 'Borrado',
                  'yellow-text': imp.ESTADO === 'Pendiente',
                  'green-text': imp.ESTADO === 'Procesando',
                  'cyan-text': imp.ESTADO === 'Finalizado'
                }">{{imp.ESTADO}}</span>
              </td>
            </tr>
          </tbody>
    </table>
  </div>
</el-card>
<br>
</div>
  <el-dialog
    v-model="this.imgVisible"
    title="IMAGEN"
    width="30%"
  >
    <div class="row">
      <vue-pdf-embed :source="imgData" name="plugin2" v-if="imgData.includes('pdf;')"/>
      <embed width="100%" height="100%" name="plugin2" :src="imgData" type="image/png" v-if="!imgData.includes('pdf;')">
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="this.imgVisible = false">Cerrar</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script>
import { mapGetters } from 'vuex'
// import config from '../../public/config.json'
import axios from 'axios'
import router from '../router'
import shared from '../shared/shared'
import back from '../components/back.vue'
import { ref } from 'vue'
import VuePdfEmbed from 'vue-pdf-embed'

export default {
  extends: shared,
  components: {
    back, VuePdfEmbed
  },
  mounted: function () {
    document.title = 'Busqueda Solicitudes'
    this.F_estado = 'Procesando'
    var self = this
    self.getFstCot()
    self.getEmpresa()
    self.getTippro()
    self.getPendienteXarea()
  },
  /* beforeMount: function () {
    var self = this
    self.getPendienteXusr()
  }, */
  watch: {
    search (newVal) {
      localStorage.setItem('search', newVal)
    }
  },
  data: function () {
    return {
      search: '',
      imgVisible: ref(false),
      imgData: '',
      COT_Fst_ID: '1',
      NroCotizacion: 1,
      tipproductos: [],
      Articulos: [],
      empresas: [],
      estados: [{ item: 'Finalizado' }, { item: 'Procesando' }, { item: 'Cerrado' }, { item: 'Rechazado' }, { item: 'Pendiente' }],
      // Filtros de búsqueda
      F_empresa: '',
      F_tippro: '',
      F_artcod: '',
      F_nroSC: '',
      F_estado: '',
      F_descrp: '',
      ResultadoBusqueda: [],
      artnom: '',
      EstadoR: [],
      limit: 10 // longitud máxima de la cadena
    }
  },
  directives: {
    truncate: {
      bind: function (el, binding) {
        const limit = binding.value
        const content = el.innerText
        let truncated = content.substring(0, limit)
        if (content.length > limit) {
          truncated += '...'
        }
        el.innerHTML = truncated
      }
    }
  },
  computed: {
    tablaPendiente () {
      // return this.ResultadoBusqueda.filter(imp => imp.descrp.includes(this.search))
      // console.log(this.ResultadoBusqueda)
      if (this.search.length > 0) {
        console.log('toLowerCase')
        return this.ResultadoBusqueda.filter(imp =>
          imp.DESCRP.toLowerCase().includes(this.search.toLowerCase()) ||
        imp.TIPPRO.toLowerCase().includes(this.search.toLowerCase()) ||
        imp.EMPRESA.toLowerCase().includes(this.search.toLowerCase()) ||
        imp.ObsCot.toLowerCase().includes(this.search.toLowerCase())
        ) || {}
      } else {
        return this.ResultadoBusqueda
      }
    },
    ...mapGetters(['getToken']),
    ...mapGetters(['isLoggedIn']),
    ...mapGetters(['getArea'])
  },
  methods: {
    formatearFecha (fecha) {
      const fechaSinZ = fecha.replace('Z', '')
      const partes = fechaSinZ.split('T')
      const fechaFormateada = partes[0] + ' ' + partes[1]
      return fechaFormateada
    },
    descrpARTcod () {
      this.artnom = this.Articulos.find(art => art.artcod === this.s_artcod).descrp
    },
    reloadPage () {
      location.reload()
    },
    VerImg (img) {
      this.imgData = img
      this.imgVisible = true
    },
    openFSL () {
      this.loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
    },
    closeFSL () {
      this.loading.close()
    },
    ResetBusqueda () {
      this.F_empresa = ''
      this.F_tippro = ''
      this.F_artcod = ''
      this.F_nroSC = ''
      this.F_estado = ''
      this.F_descrp = ''
    },
    getEmpresa: function (e) {
      var self = this
      self.openFSL()
      const url = 'http://' + this.getArea.ip + '/sc/getEmpresa/'

      axios.interceptors.response.use(function (response) {
        return response
      }, function (error) {
        if (error.response.status === 401) {
          router.push('/login')
          router.push(self.getToken.token)
        }
        return Promise.reject(error)
      })
      axios.get(url, {
        dataType: 'json',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: self.getToken.token
        },
        mode: 'no-cors',
        credentials: 'include'
      })
        .then(function (response) {
          localStorage.setItem('empresas', JSON.stringify(response.data).split('T00:00:00Z[-03:00]').join(''))
          self.empresas = JSON.parse(localStorage.getItem('empresas'))
          self.closeFSL()
        })
        .catch(function (error) {
          console.log('Catch getEmpresa')
          console.log(error)
          self.closeFSL()
        })
    },
    getTippro: function (e) {
      var self = this
      self.openFSL()
      const url = 'http://' + this.getArea.ip + '/sc/getTippro/'

      axios.interceptors.response.use(function (response) {
        return response
      }, function (error) {
        if (error.response.status === 401) {
          router.push('/login')
        }
        return Promise.reject(error)
      })
      axios.get(url, {
        dataType: 'json',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: self.getToken.token
        },
        mode: 'no-cors',
        credentials: 'include'
      })
        .then(function (response) {
          localStorage.setItem('tippro', JSON.stringify(response.data).split('T00:00:00Z[-03:00]').join(''))
          self.tipproductos = JSON.parse(localStorage.getItem('tippro'))
          self.closeFSL()
        })
        .catch(function (error) {
          console.log('Catch getTippro')
          console.log(error)
          self.closeFSL()
        })
    },
    getArtcod: function (e) {
      console.log('GetArtcod')
      var self = this
      self.openFSL()
      this.s_artcod = []
      const url = 'http://' + this.getArea.ip + '/sc/getArtcod/params;tippro=' + this.F_tippro

      axios.interceptors.response.use(function (response) {
        return response
      }, function (error) {
        if (error.response.status === 401) {
          router.push('/login')
          router.push(self.getToken.token)
        }
        return Promise.reject(error)
      })
      axios.get(url, {
        dataType: 'json',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: self.getToken.token
        },
        mode: 'no-cors',
        credentials: 'include'
      })
        .then(function (response) {
          localStorage.setItem('artcod', JSON.stringify(response.data).split('T00:00:00Z[-03:00]').join(''))
          self.Articulos = JSON.parse(localStorage.getItem('artcod'))
          // console.log(self.Articulos)
          self.closeFSL()
        })
        .catch(function (error) {
          console.log(error)
          self.closeFSL()
        })
    },
    getFstCot: function (e) {
      console.log('proximo COT ID')
      var self = this
      const url = 'http://' + this.getArea.ip + '/sc/getFstCot/'
      axios.interceptors.response.use(function (response) {
        return response
      }, function (error) {
        if (error.response.status === 401) {
          router.push('/login')
        }
        return Promise.reject(error)
      })
      axios.get(url, {
        dataType: 'json',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: self.getToken.token
        },
        mode: 'no-cors',
        credentials: 'include'
      })
        .then(function (response) {
          localStorage.setItem('FstCot', JSON.stringify(response.data).split('T00:00:00Z[-03:00]').join(''))
          self.FstCot = JSON.parse(localStorage.getItem('FstCot'))
          console.log('cotizacion con el menor indice')
          // console.log(self.FstCot[0].ID)
          self.COT_Fst_ID = self.FstCot[0].ID
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    getPendienteXarea: function (e) {
      var self = this
      self.openFSL()
      // this.s_artcod = []
      // console.log('http://' + this.getArea.ip + '/sc/getScByArea/params;id=' + this.F_nroSC + ';estado=' + this.F_estado + ';tippro=' + this.F_tippro + ';artcod=' + this.F_artcod + ';descrp=' + this.F_descrp + ';empresa=' + this.F_empresa)
      const url = 'http://' + this.getArea.ip + '/sc/getScByArea/params;id=' + this.F_nroSC + ';estado=' + this.F_estado + ';tippro=' + this.F_tippro + ';artcod=' + this.F_artcod + ';descrp=' + this.F_descrp + ';empresa=' + this.F_empresa
      axios.interceptors.response.use(function (response) {
        return response
      }, function (error) {
        if (error.response.status === 401) {
          router.push('/login')
        }
        return Promise.reject(error)
      })
      axios.get(url, {
        dataType: 'json',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: self.getToken.token
        },
        mode: 'no-cors',
        credentials: 'include'
      })
        .then(function (response) {
          localStorage.setItem('PendienteXarea', JSON.stringify(response.data).split('T00:00:00Z[-03:00]').join(''))
          self.ResultadoBusqueda = JSON.parse(localStorage.getItem('PendienteXarea'))
          self.closeFSL()
        })
        .catch(function (error) {
          console.log(error)
          self.closeFSL()
        })
    },
    getProxCot_Id: function (e) {
      console.log('proximo COT ID')
      var self = this
      const url = 'http://' + this.getArea.ip + '/sc/getProxCotId/'
      axios.interceptors.response.use(function (response) {
        return response
      }, function (error) {
        if (error.response.status === 401) {
          router.push('/login')
        }
        return Promise.reject(error)
      })
      axios.get(url, {
        dataType: 'json',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: self.getToken.token
        },
        mode: 'no-cors',
        credentials: 'include'
      })
        .then(function (response) {
          localStorage.setItem('NroSolicitud', JSON.stringify(response.data).split('T00:00:00Z[-03:00]').join(''))
          self.NroCotizacion = JSON.parse(localStorage.getItem('NroSolicitud'))
          self.COT_ID = self.NroCotizacion[0].ID
          console.log('COT ID:' + self.COT_ID)
          self.consolidar()
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  }
}
</script>
<style>
  tr:hover {
    background-color: rgb(6, 10, 39);
  }
</style>
